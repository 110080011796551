import React from 'react';
import { PageKey, EvidenceAPI, Permission } from '../../enums';
import { ThirdPartySystem } from '../../models/third-party-system';
import { Evidence2 } from '@eas/common-web';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { ExportTags } from '../../enums/export-tags';
import { useColumns } from './third-party-systems-columns';
import { ThirdPartySystemsFields } from './third-party-systems-fields';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useValidationSchema } from './third-party-systems-schema';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';
import { ThirdPartySystemsTableToolbar } from './third-party-systems-table-toolbar';
import { useHistoryDescriptors } from './third-party-systems-history';

export function ThirdPartySystems() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<ThirdPartySystem>({
    options: {
      NEW: [Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_CREATE],
      EDIT: [Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_UPDATE],
      REMOVE: [Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_DELETE],
      CHECK: [
        Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_CREATE,
        Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_UPDATE,
      ],
      SAVE: [
        Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_CREATE,
        Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_UPDATE,
      ],
      CLOSE: [
        Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_CREATE,
        Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_UPDATE,
      ],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<ThirdPartySystem>({
    identifier: PageKey.THIRD_PARTY_SYSTEMS,
    apiProps: {
      url: EvidenceAPI.THIRD_PARTY_SYSTEMS,
    },
    tableProps: {
      defaultSorts: [],
      tableName: 'Přehled systémů třetích stran',
      reportTag: ExportTags.THIRD_PARTY_SYSTEMS,
      columns: useColumns(),
      toolbarProps: {
        after: <ThirdPartySystemsTableToolbar />,
      },
    },
    detailProps: {
      FieldsComponent: ThirdPartySystemsFields,
      validationSchema,
      toolbarProps: {
        title: 'Systém třetí strany',
        subtitle: useSubTitle<ThirdPartySystem>((data) => data?.name ?? ''),
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_LIST,
    },
  });

  return <Evidence2 {...evidence} />;
}
