import React, { useContext, useMemo } from 'react';
import { Evidence2, UserContext } from '@eas/common-web';
import { ProductionSite, Me, Exception } from '../../models';
import { ProductionSitesFields } from './production-sites-fields';
import { useColumns } from './production-sites-columns';
import { useValidationSchema } from './production-sites-schema';
import { useMapEvidence } from '../../components/evidence/map-evidence/map-evidence';
import { ProductionSitesMap } from './production-sites-map';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  IncludeOptions,
  Messages,
} from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { ExportTags } from '../../enums/export-tags';
import { EntityType } from '../../enums/entity-type';
import { ProductionSitesActionbar } from './production-sites-actionbar';
import { MapEvidenceContext } from '../../components/evidence/map-evidence/map-context';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useSpecialFilters } from './production-sites-special-filters';
import { ProductionSitesTableToolbar } from './production-sites-table-toolbar';
import { combineGetCalls } from './production-sites-api';
import {
  createItemFactory,
  getItemFactory,
  updateItemFactory,
} from '../../utils/custom-crud';
import { useHistoryDescriptors } from './production-sites-history';
import { getErrorMessage } from '../../utils/get-message';

export function ProductionSites() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<ProductionSite>({
    options: {
      NEW: [Permission.ProductionSite.PRODUCTION_SITE_CREATE],
      EDIT: [Permission.ProductionSite.PRODUCTION_SITE_UPDATE],
      REMOVE: [],
      CHECK: [
        Permission.ProductionSite.PRODUCTION_SITE_CREATE,
        Permission.ProductionSite.PRODUCTION_SITE_UPDATE,
      ],
      SAVE: [
        Permission.ProductionSite.PRODUCTION_SITE_CREATE,
        Permission.ProductionSite.PRODUCTION_SITE_UPDATE,
      ],
      CLOSE: [
        Permission.ProductionSite.PRODUCTION_SITE_CREATE,
        Permission.ProductionSite.PRODUCTION_SITE_UPDATE,
      ],
    },
  });

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const canSeeDeactivated = useMemo(
    () => hasPermission(Permission.ProductionSite.PRODUCTION_SITE_REVALIDATE),
    [hasPermission]
  );

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  const historyDescriptors = useHistoryDescriptors();

  const { evidence, context } = useMapEvidence<ProductionSite>({
    version: 3,
    identifier: PageKey.PRODUCTION_SITES,
    noteEntityType: EntityType.PRODUCTION_SITE,
    apiProps: {
      url: EvidenceAPI.PRODUCTION_SITES,
      getItem: getItemFactory({ combineGetCalls }),
      createItem: createItemFactory({
        combineGetCalls,
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.ProductionSite.CREATE,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
      updateItem: updateItemFactory({
        combineGetCalls,
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.ProductionSite.EDIT,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
    },
    tableProps: {
      columns: useColumns(canSeeDeactivated),
      tableName: 'Přehled průmyslových míst',
      reportTag: ExportTags.PRODUCTION_SITES,
      include,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.ProductionSite.PRODUCTION_SITE_LIST,
      toolbarProps: {
        after: <ProductionSitesTableToolbar />,
      },
    },
    detailProps: {
      FieldsComponent: ProductionSitesFields,
      MapComponent: ProductionSitesMap,
      validationSchema,
      toolbarProps: {
        title: 'Průmyslové místo',
        subtitle: useSubTitle<ProductionSite>((data) => data?.name ?? ''),
        ActionBar: ProductionSitesActionbar,
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.ProductionSite.PRODUCTION_SITE_LIST,
    },
  });

  return (
    <MapEvidenceContext.Provider value={context}>
      <Evidence2 {...evidence} />
    </MapEvidenceContext.Provider>
  );
}
