import React, { useMemo, useState, useContext } from 'react';
import { Evidence2, DetailHandle, DetailContext } from '@eas/common-web';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { DictionaryActionbar } from '../../components/evidence/dictionary-evidence/dictionary-actionbar';
import { DirpRecordsFields } from './dirp-records-fields';
import { useColumns } from './dirp-records-columns';
import { useHistoryDescriptors } from './dirp-records-history';
import { useValidationSchema } from './dirp-records-schema';
import { DirpRecord } from '../../models';
import { Permission, PageKey, EvidenceAPI, IncludeOptions } from '../../enums';
import { ExportTags } from '../../enums/export-tags';

import { DirpRecordsFileViewer } from './dirp-records-file-viewer';
import { Toolbar } from './dirp-records-toolbar';

export function DirpRecords() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const historyDescriptors = useHistoryDescriptors();

  const [view, setView] = useState<'FORM' | 'FILE'>('FORM');

  const permissions = usePermission({
    options: {
      NEW: [Permission.Dirp.DIRP_CREATE],
      EDIT: [Permission.Dirp.DIRP_UPDATE],
      REMOVE: [Permission.Dirp.DIRP_DELETE],
      CHECK: [Permission.Dirp.DIRP_CREATE, Permission.Dirp.DIRP_UPDATE],
      SAVE: [Permission.Dirp.DIRP_CREATE, Permission.Dirp.DIRP_UPDATE],
      CLOSE: [Permission.Dirp.DIRP_CREATE, Permission.Dirp.DIRP_UPDATE],
    },
  });

  const include = useMemo(() => [IncludeOptions.DEACTIVATED], []);

  const FieldsComponent =
    view === 'FORM' ? DirpRecordsFields : DirpRecordsFileViewer;

  const evidence = useAuthoredEvidence<DirpRecord>({
    identifier: PageKey.DIRP_RECORDS,
    apiProps: {
      url: EvidenceAPI.DIRP_RECORDS,
    },
    tableProps: {
      columns,
      tableName: 'Záznamy databáze informací pro rozhodovací praxi',
      reportTag: ExportTags.DIRP_RECORDS,
      include,
      defaultSorts: [],
      onRowClick: () => {
        setView('FORM');
      },
    },
    detailProps: {
      validationSchema,
      FieldsComponent,
      hideGeneralFields: view === 'FILE',
      reportTag: ExportTags.DIRP_RECORDS,
      toolbarProps: {
        title: 'Záznam DIRP',
        showBtn: permissions,
        disableBtn: function useDisable(btn) {
          const { source } = useContext<DetailHandle<DirpRecord>>(
            DetailContext
          );

          if (btn === 'SHARE' && view === 'FILE') {
            return false;
          }

          if (source?.data?.active && btn === 'REMOVE') {
            return true;
          }

          return view === 'FILE';
        },
        ActionBar: function ActionBar() {
          return (
            <>
              {view === 'FORM' && (
                <DictionaryActionbar
                  url={EvidenceAPI.DIRP_RECORDS}
                  invalidatePerm={Permission.Dirp.DIRP_INVALIDATE}
                  revalidatePerm={Permission.Dirp.DIRP_REVALIDATE}
                />
              )}
            </>
          );
        },
        After: function After() {
          return <Toolbar view={view} setView={setView} />;
        },
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Dirp.DIRP_LIST,
    },
  });

  return <Evidence2 {...evidence} />;
}
