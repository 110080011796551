import React from 'react';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { PersonalData as PersonalDataModel } from '../../models';
import { EvidenceAPI, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { PersonalDataFields } from './personal-data-fields';
import { useColumns } from './personal-data-columns';
import { bulkActions } from './bulk-actions/bulk-actions';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';

export function PersonalData() {
  const permissions = usePermission({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [Permission.PersonalData.PERSONAL_DATA_DELETE],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useAuthoredEvidence<PersonalDataModel>({
    identifier: EvidenceAPI.PERSONAL_DATA,
    apiProps: {
      url: EvidenceAPI.PERSONAL_DATA,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled osobních údajů',
      reportTag: ExportTags.PERSONAL_DATA,
      defaultSorts: [],
      bulkActions,
      showColumn: (column) => {
        if (column === 'CREATED_BY') {
          return false;
        }
        if (column === 'CREATED') {
          return false;
        }
        if (column === 'UPDATED') {
          return false;
        }
        return true;
      },
    },
    detailProps: {
      FieldsComponent: PersonalDataFields,
      toolbarProps: {
        title: 'Osobní údaj',
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
      hideGeneralFields: true,
    },
  });
  return <Evidence2 {...evidence} />;
}
