import { NavigationContext, MenuItem, UserContext } from '@eas/common-web';
import { useContext, useMemo } from 'react';
import { EvidenceUrl, Permission } from '../../../enums';
import { BoilerPermissionContext } from '../../boiler-permission/boiler-permission-context';
import { Me } from '../../../models';

export type CrzpMenuItem = MenuItem & {
  permission?: string[];
  displayItem?: boolean;
};

export const useMenuBarItems = () => {
  const { navigate } = useContext(NavigationContext);
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { hasBoilerPermissions } = useContext(BoilerPermissionContext);

  const items: CrzpMenuItem[] = useMemo(
    () => [
      {
        label: 'Subjekt',
        isActive: (pathname) => {
          return [
            EvidenceUrl.SUBJECTS,
            EvidenceUrl.SUBJECT_REQUESTS,
            EvidenceUrl.SUBJECT_REQUESTS_NEW,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled subjektů',
            onClick: () => navigate(EvidenceUrl.SUBJECTS),
            href: EvidenceUrl.SUBJECTS,
            permission: [
              Permission.Subject.SUBJECT_LIST_ALL,
              Permission.Subject.SUBJECT_LIST_OWN,
              Permission.Subject.SUBJECT_LIST_WITHOUT_PERSONAL,
            ],
          },
          {
            label: 'Přehled žádostí o registraci subjektu',
            onClick: () => navigate(EvidenceUrl.SUBJECT_REQUESTS),
            href: EvidenceUrl.SUBJECT_REQUESTS,
            permission: [Permission.Default.LOGGED_USER],
          },
          {
            label: '',
            separator: true,
            permission: [Permission.Default.LOGGED_USER],
          },
          {
            label: 'Nová registrace subjektu',
            onClick: () => navigate(EvidenceUrl.SUBJECT_REQUESTS_NEW),
            href: EvidenceUrl.SUBJECT_REQUESTS_NEW,
            permission: [Permission.Default.LOGGED_USER],
          },
        ],
      },
      {
        label: 'Provozovny OVZ',
        isActive: (pathname) => {
          return [
            EvidenceUrl.OVZ_FACILITIES,
            EvidenceUrl.OVZ_FACILITY_NEW,
            EvidenceUrl.OVZ_FACILITY_REQUEST_OPERATOR_CHANGE,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled provozoven OVZ',
            onClick: () => navigate(EvidenceUrl.OVZ_FACILITIES),
            href: EvidenceUrl.OVZ_FACILITIES,
            permission: [
              Permission.OvzFacility.OVZ_LIST_ALL,
              Permission.OvzFacility.OVZ_LIST_OWN,
            ],
          },
          {
            label: 'Přehled nabídek provozoven OVZ',
            onClick: () => navigate(EvidenceUrl.OVZ_FACILITY_REQUESTS),
            href: EvidenceUrl.OVZ_FACILITY_REQUESTS,
            permission: [
              Permission.OvzFacility.OVZ_REQUEST_LIST_ALL,
              Permission.OvzFacility.OVZ_REQUEST_LIST_OWN,
            ],
          },
          {
            label: '',
            separator: true,
            permission: [
              Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_SUBMIT,
            ],
          },
          {
            label: 'Nabídka změny provozovatele',
            onClick: () =>
              navigate(EvidenceUrl.OVZ_FACILITY_REQUEST_OPERATOR_CHANGE),
            href: EvidenceUrl.OVZ_FACILITY_REQUEST_OPERATOR_CHANGE,
            permission: [
              Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_SUBMIT,
            ],
          },
          {
            label: '',
            separator: true,
            permission: [
              Permission.OvzFacility.OVZ_CREATE_ALL,
              Permission.OvzFacility.OVZ_CREATE_OWN,
            ],
          },
          {
            label: 'Nová registrace provozovny OVZ',
            onClick: () => navigate(EvidenceUrl.OVZ_FACILITY_NEW),
            href: EvidenceUrl.OVZ_FACILITY_NEW,
            permission: [
              Permission.OvzFacility.OVZ_CREATE_ALL,
              Permission.OvzFacility.OVZ_CREATE_OWN,
            ],
          },
        ],
      },
      {
        label: 'Provozovny IRZ',
        isActive: (pathname) => {
          return [
            EvidenceUrl.IRZ_FACILITIES,
            EvidenceUrl.IRZ_FACILITY_REQUESTS,
            EvidenceUrl.IRZ_FACILITY_REQUEST_NEW,
            EvidenceUrl.IRZ_FACILITY_REQUEST_CHANGE,
            EvidenceUrl.IRZ_FACILITY_REQUEST_OPERATOR_CHANGE,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled provozoven IRZ',
            onClick: () => navigate(EvidenceUrl.IRZ_FACILITIES),
            href: EvidenceUrl.IRZ_FACILITIES,
            permission: [
              Permission.IrzFacility.IRZ_LIST_ALL,
              Permission.IrzFacility.IRZ_LIST_OWN,
            ],
          },
          {
            label: 'Přehled žádostí provozoven IRZ',
            onClick: () => navigate(EvidenceUrl.IRZ_FACILITY_REQUESTS),
            href: EvidenceUrl.IRZ_FACILITY_REQUESTS,
            permission: [
              Permission.IrzFacility.IRZ_REQUEST_LIST_ALL,
              Permission.IrzFacility.IRZ_REQUEST_LIST_OWN,
            ],
          },
          {
            label: '',
            separator: true,
            permission: [
              Permission.IrzFacility.IRZ_REQUEST_UPDATE_SUBMIT,
              Permission.IrzFacility.IRZ_REQUEST_CHANGE_OPERATOR_SUBMIT,
            ],
          },
          {
            label: 'Žádost o změnu provozovatele',
            onClick: () =>
              navigate(EvidenceUrl.IRZ_FACILITY_REQUEST_OPERATOR_CHANGE),
            href: EvidenceUrl.IRZ_FACILITY_REQUEST_OPERATOR_CHANGE,
            permission: [
              Permission.IrzFacility.IRZ_REQUEST_CHANGE_OPERATOR_SUBMIT,
            ],
          },
          {
            label: 'Žádost o změnu údajů',
            onClick: () => navigate(EvidenceUrl.IRZ_FACILITY_REQUEST_CHANGE),
            href: EvidenceUrl.IRZ_FACILITY_REQUEST_CHANGE,
            permission: [Permission.IrzFacility.IRZ_REQUEST_UPDATE_SUBMIT],
          },
          {
            label: '',
            separator: true,
            permission: [Permission.IrzFacility.IRZ_REQUEST_CREATE_SUBMIT],
          },
          {
            label: 'Nová registrace provozovny IRZ',
            onClick: () => navigate(EvidenceUrl.IRZ_FACILITY_REQUEST_NEW),
            href: EvidenceUrl.IRZ_FACILITY_REQUEST_NEW,
            permission: [Permission.IrzFacility.IRZ_REQUEST_CREATE_SUBMIT],
          },
        ],
      },
      {
        label: 'Místa užívání vody',
        isActive: (pathname) => {
          return [
            EvidenceUrl.WATER_USAGE_PLACES,
            EvidenceUrl.WATER_MANAGEMENT_DECISIONS,
            EvidenceUrl.WATER_MANAGEMENT_DECISION_PARTS,
            EvidenceUrl.DICTIONARY_RIVER_SIDES,
            EvidenceUrl.DICTIONARY_DECISION_TYPES,
            EvidenceUrl.DICTIONARY_BALANCE_UNITS,
            EvidenceUrl.DICTIONARY_WATER_ORIGINS,
            EvidenceUrl.DICTIONARY_SEWER_TYPES,
            EvidenceUrl.DICTIONARY_BALANCE_UNITS,
            EvidenceUrl.DICTIONARY_SAMPLE_TYPES,
            EvidenceUrl.DICTIONARY_WATER_FLOWS,
            EvidenceUrl.DICTIONARY_WATER_TREATMENT_METHODS,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled míst užívání vody',
            onClick: () => navigate(EvidenceUrl.WATER_USAGE_PLACES),
            href: EvidenceUrl.WATER_USAGE_PLACES,
            permission: [
              Permission.WaterUsagePlace.WATER_USAGE_PLACE_LIST_ALL,
              Permission.WaterUsagePlace.WATER_USAGE_PLACE_LIST_OWN,
            ],
          },
          {
            label: 'Přehled rozhodnutí MUV',
            onClick: () => navigate(EvidenceUrl.WATER_MANAGEMENT_DECISIONS),
            href: EvidenceUrl.WATER_MANAGEMENT_DECISIONS,
            permission: [
              Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_LIST_ALL,
              Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_LIST_OWN,
            ],
          },
          {
            label: 'Přehled částí rozhodnutí MUV',
            onClick: () =>
              navigate(EvidenceUrl.WATER_MANAGEMENT_DECISION_PARTS),
            href: EvidenceUrl.WATER_MANAGEMENT_DECISION_PARTS,
            permission: [
              Permission.WaterUsagePlace
                .WATER_USAGE_PLACE_DECISION_PART_LIST_ALL,
              Permission.WaterUsagePlace
                .WATER_USAGE_PLACE_DECISION_PART_LIST_OWN,
            ],
          },
          {
            label: '',
            separator: true,
            permission: [
              Permission.Dictionary.DICTIONARY_LIST,
              Permission.MuvRiverSide.MUV_RIVER_SIDE_LIST,
              Permission.MuvDecisionType.MUV_DECISION_TYPE_LIST,
              Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_LIST,
              Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_LIST,
              Permission.MuvSewerType.MUV_SEWER_TYPE_LIST,
              Permission.MuvSampleType.MUV_SAMPLE_TYPE_LIST,
              Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_LIST,
              Permission.MuvWaterFlow.MUV_WATER_FLOW_LIST,
              Permission.MuvIndicator.MUV_INDICATOR_LIST,
            ],
          },
          {
            label: 'Břehy vodních toků',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_RIVER_SIDES),
            href: EvidenceUrl.DICTIONARY_RIVER_SIDES,
            permission: [Permission.MuvRiverSide.MUV_RIVER_SIDE_LIST],
          },
          {
            label: 'Druhy rozhodnutí',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_DECISION_TYPES),
            href: EvidenceUrl.DICTIONARY_DECISION_TYPES,
            permission: [Permission.MuvDecisionType.MUV_DECISION_TYPE_LIST],
          },
          {
            label: 'Hydrogeologické rajóny',
            onClick: () =>
              navigate(EvidenceUrl.DICTIONARY_HYDROGEOLOGICAL_ZONES),
            href: EvidenceUrl.DICTIONARY_HYDROGEOLOGICAL_ZONES,
            permission: [
              Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_LIST,
            ],
          },
          {
            label: 'Původy odebrané vody',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_WATER_ORIGINS),
            href: EvidenceUrl.DICTIONARY_WATER_ORIGINS,
            permission: [Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_LIST],
          },
          {
            label: 'Typy kanalizace',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_SEWER_TYPES),
            href: EvidenceUrl.DICTIONARY_SEWER_TYPES,
            permission: [Permission.MuvSewerType.MUV_SEWER_TYPE_LIST],
          },
          {
            label: 'Typy vzorků',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_SAMPLE_TYPES),
            href: EvidenceUrl.DICTIONARY_SAMPLE_TYPES,
            permission: [Permission.MuvSampleType.MUV_SAMPLE_TYPE_LIST],
          },
          {
            label: 'Ukazetele VHR',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_INDICATORS),
            href: EvidenceUrl.DICTIONARY_INDICATORS,
            permission: [Permission.MuvIndicator.MUV_INDICATOR_LIST],
          },
          {
            label: 'Vodní toky',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_WATER_FLOWS),
            href: EvidenceUrl.DICTIONARY_WATER_FLOWS,
            permission: [Permission.MuvWaterFlow.MUV_WATER_FLOW_LIST],
          },
          {
            label: 'Způsoby úpravy vody',
            onClick: () =>
              navigate(EvidenceUrl.DICTIONARY_WATER_TREATMENT_METHODS),
            href: EvidenceUrl.DICTIONARY_WATER_TREATMENT_METHODS,
            permission: [
              Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_LIST,
            ],
          },
        ],
      },
      {
        label: 'Registr OZO',
        isActive: (pathname) => {
          return [
            EvidenceUrl.BOILER_MANUFACTURERS,
            EvidenceUrl.BOILER_MANUFACTURER_NEW,
            EvidenceUrl.BOILER_PERMISSIONS,
            EvidenceUrl.BOILER_PERMISSIONS_NEW,
            EvidenceUrl.OZO_SUBJECTS,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled oprávnění OZO',
            onClick: () => navigate(EvidenceUrl.BOILER_PERMISSIONS),
            href: EvidenceUrl.BOILER_PERMISSIONS,
            permission: [
              Permission.Ozo.OZO_PERMISSION_LIST_ALL,
              Permission.Ozo.OZO_PERMISSION_LIST_OWN,
            ],
            displayItem: hasBoilerPermissions,
          },
          {
            label: 'Přehled subjektů OZO',
            onClick: () => navigate(EvidenceUrl.OZO_SUBJECTS),
            href: EvidenceUrl.OZO_SUBJECTS,
            permission: [Permission.Subject.SUBJECT_LIST_OZO],
          },
          {
            label: 'Přehled výrobců kotlů',
            onClick: () => navigate(EvidenceUrl.BOILER_MANUFACTURERS),
            href: EvidenceUrl.BOILER_MANUFACTURERS,
            permission: [
              Permission.Ozo.OZO_MANUFACTURER_LIST_ALL,
              Permission.Ozo.OZO_MANUFACTURER_LIST_OWN,
            ],
          },
          {
            label: '',
            separator: true,
            permission: [
              Permission.Ozo.OZO_PERMISSION_AGENDA_ASSIGN,
              Permission.Ozo.OZO_MANUFACTURER_UPDATE,
            ],
          },
          {
            label: 'Nové oprávnění OZO',
            onClick: () => navigate(EvidenceUrl.BOILER_PERMISSIONS_NEW),
            href: EvidenceUrl.BOILER_PERMISSIONS_NEW,
            permission: [Permission.Ozo.OZO_PERMISSION_AGENDA_ASSIGN],
          },
          {
            label: 'Nový výrobce OZO',
            onClick: () => navigate(EvidenceUrl.BOILER_MANUFACTURER_NEW),
            href: EvidenceUrl.BOILER_MANUFACTURER_NEW,
            permission: [Permission.Ozo.OZO_MANUFACTURER_UPDATE],
          },
          {
            label: '',
            separator: true,
            permission: [Permission.Ozo.OZO_BRAND_LIST_ALL],
          },
          {
            label: 'Přehled modelů kotlů',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_BOILER_MODELS),
            href: EvidenceUrl.DICTIONARY_BOILER_MODELS,
            permission: [Permission.Ozo.OZO_MODEL_LIST_ALL],
          },
          {
            label: 'Přehled značek kotlů',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_BOILER_BRANDS),
            href: EvidenceUrl.DICTIONARY_BOILER_BRANDS,
            permission: [Permission.Ozo.OZO_BRAND_LIST_ALL],
          },
        ],
      },
      {
        label: 'Zmocnění',
        isActive: (pathname) => {
          return [
            EvidenceUrl.EMPOWERMENTS,
            EvidenceUrl.EMPOWERMENT_REQUESTS,
            EvidenceUrl.EMPOWERMENT_REQUESTS_NEW,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled zmocnění',
            onClick: () => navigate(EvidenceUrl.EMPOWERMENTS),
            href: EvidenceUrl.EMPOWERMENTS,
            permission: [
              Permission.Empowerment.EMPOWERMENT_LIST_ALL,
              Permission.Empowerment.EMPOWERMENT_REQUEST_LIST_OWN,
            ],
          },
          {
            label: 'Přehled žádostí o zmocnění',
            onClick: () => navigate(EvidenceUrl.EMPOWERMENT_REQUESTS),
            href: EvidenceUrl.EMPOWERMENT_REQUESTS,
            permission: [
              Permission.Empowerment.EMPOWERMENT_REQUEST_LIST_ALL,
              Permission.Empowerment.EMPOWERMENT_REQUEST_LIST_OWN,
            ],
          },
          {
            label: '',
            separator: true,
            permission: [
              Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_ALL,
              Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_OWN,
            ],
          },
          {
            label: 'Nová žádost o zmocnění',
            onClick: () => navigate(EvidenceUrl.EMPOWERMENT_REQUESTS_NEW),
            href: EvidenceUrl.EMPOWERMENT_REQUESTS_NEW,
            permission: [
              Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_ALL,
              Permission.Empowerment.EMPOWERMENT_REQUEST_CREATE_OWN,
            ],
          },
        ],
      },
      {
        label: 'Průmyslová místa',
        isActive: (pathname) => {
          return [EvidenceUrl.PRODUCTION_SITES].includes(
            pathname as EvidenceUrl
          );
        },
        items: [
          {
            label: 'Přehled průmyslových míst',
            onClick: () => navigate(EvidenceUrl.PRODUCTION_SITES),
            href: EvidenceUrl.PRODUCTION_SITES,
            permission: [Permission.ProductionSite.PRODUCTION_SITE_LIST],
          },
        ],
      },
      {
        label: 'Chráněná ložisková území',
        isActive: (pathname) => {
          return [
            EvidenceUrl.PROTECTED_DEPOSIT_AREAS,
            EvidenceUrl.DICTIONARY_OVSS,
            EvidenceUrl.DICTIONARY_DEPOSITS,
            EvidenceUrl.DICTIONARY_MINERAL_GROUPS,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled chráněných ložiskových území',
            onClick: () => navigate(EvidenceUrl.PROTECTED_DEPOSIT_AREAS),
            href: EvidenceUrl.PROTECTED_DEPOSIT_AREAS,
            permission: [Permission.Chlu.CHLU_LIST],
          },
          {
            label: '',
            separator: true,
            permission: [
              Permission.Dictionary.DICTIONARY_LIST,
              Permission.Deposit.DEPOSIT_LIST,
            ],
          },
          {
            label: 'Obory výkonu státní správy',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_OVSS),
            href: EvidenceUrl.DICTIONARY_OVSS,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Ložiska',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_DEPOSITS),
            href: EvidenceUrl.DICTIONARY_DEPOSITS,
            permission: [Permission.Deposit.DEPOSIT_LIST],
          },
          {
            label: 'Skupiny nerostů',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_MINERAL_GROUPS),
            href: EvidenceUrl.DICTIONARY_MINERAL_GROUPS,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
        ],
      },
      {
        label: 'Modul DIRP',
        isActive: (pathname) => {
          return [
            EvidenceUrl.DIRP_RECORDS,
            EvidenceUrl.DICTIONARY_DIRP_AREAS,
            EvidenceUrl.DICTIONARY_DIRP_AUTHORS,
            EvidenceUrl.DICTIONARY_DIRP_TYPES,
            EvidenceUrl.DICTIONARY_DIRP_LAWS,
            EvidenceUrl.DICTIONARY_DIRP_LAW_PARAGRAPHS,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled DIRP',
            onClick: () => navigate(EvidenceUrl.DIRP_RECORDS),
            href: EvidenceUrl.DIRP_RECORDS,
            permission: [Permission.Dirp.DIRP_LIST],
          },
          {
            label: '',
            separator: true,
            permission: [
              Permission.Dictionary.DICTIONARY_LIST,
              Permission.Dirp.DIRP_LAW_READ,
            ],
          },
          {
            label: 'Autoři',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_DIRP_AUTHORS),
            href: EvidenceUrl.DICTIONARY_DIRP_AUTHORS,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Oblasti',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_DIRP_AREAS),
            href: EvidenceUrl.DICTIONARY_DIRP_AREAS,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Paragrafy předpisů',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_DIRP_LAW_PARAGRAPHS),
            href: EvidenceUrl.DICTIONARY_DIRP_LAW_PARAGRAPHS,
            permission: [Permission.Dirp.DIRP_LAW_READ],
          },
          {
            label: 'Předpisy',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_DIRP_LAWS),
            href: EvidenceUrl.DICTIONARY_DIRP_LAWS,
            permission: [Permission.Dirp.DIRP_LAW_READ],
          },
          {
            label: 'Typy',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_DIRP_TYPES),
            href: EvidenceUrl.DICTIONARY_DIRP_TYPES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
        ],
      },
      {
        label: 'Veřejné seznamy',
        isActive: (pathname) => {
          return [
            EvidenceUrl.PUBLIC_LISTS,
            EvidenceUrl.PUBLIC_LIST_PUBLISHINGS,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled veřejných seznamů',
            onClick: () => navigate(EvidenceUrl.PUBLIC_LISTS),
            href: EvidenceUrl.PUBLIC_LISTS,
            permission: [Permission.PublicList.PUBLIC_LIST_LIST],
          },
          {
            label: 'Publikace veřejných seznamů',
            onClick: () => navigate(EvidenceUrl.PUBLIC_LIST_PUBLISHINGS),
            href: EvidenceUrl.PUBLIC_LIST_PUBLISHINGS,
            permission: [Permission.PublicList.PUBLIC_LIST_LIST],
          },
        ],
      },
      {
        label: 'Reporting',
        isActive: (pathname) => {
          return [
            EvidenceUrl.REPORTING_DATA,
            EvidenceUrl.OVERVIEW_MAP,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehledová mapa',
            onClick: () => navigate(EvidenceUrl.OVERVIEW_MAP),
            href: EvidenceUrl.OVERVIEW_MAP,
            permission: [Permission.MapOverview.MAP_OVERVIEW],
          },
          {
            label: 'Přehled reportů',
            onClick: () => navigate(EvidenceUrl.REPORTING_DATA),
            href: EvidenceUrl.REPORTING_DATA,
            permission: [
              Permission.Reporting.REPORTING_DETAIL,
              Permission.Reporting.REPORTING_DETAIL_OWN,
            ],
          },
        ],
      },
      {
        label: 'Uživatelé',
        isActive: (pathname) => {
          return [
            EvidenceUrl.USERS,
            EvidenceUrl.ROLE_INSTANCES,
            EvidenceUrl.UNCONFIRMED_USER_REGISTRATIONS,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled uživatelů',
            onClick: () => navigate(EvidenceUrl.USERS),
            href: EvidenceUrl.USERS,
            permission: [
              Permission.User.USER_LIST_ALL,
              Permission.User.USER_LIST_WITHOUT_PERSONAL,
            ],
          },
          {
            label: 'Přehled neaktivovaných uživatelů',
            onClick: () => navigate(EvidenceUrl.UNCONFIRMED_USER_REGISTRATIONS),
            href: EvidenceUrl.UNCONFIRMED_USER_REGISTRATIONS,
            permission: [Permission.User.USER_REGISTRATION_LIST],
          },
          {
            label: 'Přehled přiřazených rolí',
            onClick: () => navigate(EvidenceUrl.ROLE_INSTANCES),
            href: EvidenceUrl.ROLE_INSTANCES,
            permission: [
              Permission.Role.ROLE_INSTANCE_LIST,
              Permission.Role.ROLE_INSTANCE_LIST_RELATIONSHIP_ROLE,
              Permission.Role.ROLE_INSTANCE_LIST_SECURITY_ROLE,
              Permission.Role.ROLE_INSTANCE_LIST_SYSTEM_ROLE,
              Permission.Role.ROLE_INSTANCE_LIST_USER_ROLE,
              Permission.Role.ROLE_INSTANCE_LIST_SELECTED,
            ],
          },
          {
            label: 'Přehled žádostí o role',
            onClick: () => navigate(EvidenceUrl.ROLES_REQUESTS),
            href: EvidenceUrl.ROLES_REQUESTS,
            displayItem:
              (user?.subjectPermissions &&
                Object.values(user?.subjectPermissions)?.length > 0) ||
              hasPermission(Permission.RoleRequest.ROLE_REQUEST_LIST_ALL) ||
              hasPermission(Permission.RoleRequest.ROLE_REQUEST_LIST_OWN),
          },
          {
            label: '',
            separator: true,
            displayItem:
              user?.subjectPermissions &&
              Object.values(user?.subjectPermissions)?.length > 0,
          },
          {
            label: 'Nová žádost o roli',
            onClick: () => navigate(EvidenceUrl.ROLE_REQUESTS_NEW),
            href: EvidenceUrl.ROLE_REQUESTS_NEW,
            displayItem:
              user?.subjectPermissions &&
              Object.values(user?.subjectPermissions)?.length > 0,
          },
        ],
      },
      {
        label: 'Administrace',
        isActive: (pathname) => {
          return [
            EvidenceUrl.ADMIN_SCHEDULE_JOBS,
            EvidenceUrl.ADMIN_SCHEDULE_RUNS,
            EvidenceUrl.ADMIN_ACTIONS,
            EvidenceUrl.AGENDAS,
            EvidenceUrl.NOTIFICATION_TEMPLATES,
            EvidenceUrl.NOTIFICATION_BATCHES,
            EvidenceUrl.MAILING,
            EvidenceUrl.OUTAGES,
            EvidenceUrl.ROLES,
            EvidenceUrl.SOAP_EXECUTIONS,
            EvidenceUrl.TRANSLATIONS,
            EvidenceUrl.WEB_SERVICES,
            EvidenceUrl.EXPORT_TEMPLATES,
            EvidenceUrl.EXPORT_ACCESS_RULES,
            EvidenceUrl.REPORTING_ACCESS_RULES,
            EvidenceUrl.ISPOP_DOCUMENTS,
            EvidenceUrl.THIRD_PARTY_SYSTEMS,
            EvidenceUrl.EXPORT_REQUESTS,
            EvidenceUrl.SOAP_MESSAGES,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled agend',
            onClick: () => navigate(EvidenceUrl.AGENDAS),
            href: EvidenceUrl.AGENDAS,
            permission: [Permission.Agenda.AGENDA_LIST],
          },
          {
            label: 'Přehled AIS',
            onClick: () => navigate(EvidenceUrl.SYSTEMS),
            href: EvidenceUrl.SYSTEMS,
            permission: [Permission.System.SYSTEM_LIST],
          },
          {
            label: 'Přehled asynchronních požadavků na exporty',
            onClick: () => navigate(EvidenceUrl.EXPORT_REQUESTS),
            href: EvidenceUrl.EXPORT_REQUESTS,
            permission: [Permission.Export.EXPORT_REQUEST_LIST],
          },
          {
            label: 'Přehled auditních logů',
            onClick: () => navigate(EvidenceUrl.ALOG),
            href: EvidenceUrl.ALOG,
            permission: [Permission.Alog.ALOG_LIST],
          },
          {
            label: 'Přehled logů SOAP volání',
            onClick: () => navigate(EvidenceUrl.SOAP_EXECUTIONS),
            href: EvidenceUrl.SOAP_EXECUTIONS,
            permission: [Permission.WSExecution.WS_EXECUTION_LIST],
          },
          {
            label: 'Přehled mailových kampaní',
            onClick: () => navigate(EvidenceUrl.MAILING),
            href: EvidenceUrl.MAILING,
            permission: [Permission.Mailing.MAILING_LIST],
          },
          {
            label: 'Přehled nápověd',
            onClick: () => navigate(EvidenceUrl.TRANSLATIONS),
            href: EvidenceUrl.TRANSLATIONS,
            permission: [Permission.Help.HELP_LIST],
          },
          {
            label: 'Přehled notifikačních dávek',
            onClick: () => navigate(EvidenceUrl.NOTIFICATION_BATCHES),
            href: EvidenceUrl.NOTIFICATION_BATCHES,
            permission: [Permission.Notification.NOTIFICATION_BATCH_LIST],
          },
          {
            label: 'Přehled odstávek a výpadků',
            onClick: () => navigate(EvidenceUrl.OUTAGES),
            href: EvidenceUrl.OUTAGES,
            permission: [Permission.Outage.OUTAGE_LIST],
          },
          {
            label: 'Přehled osobních údajů',
            onClick: () => navigate(EvidenceUrl.PERSONAL_DATA),
            href: EvidenceUrl.PERSONAL_DATA,
            permission: [Permission.PersonalData.PERSONAL_DATA_LIST_ALL],
          },
          {
            label: 'Přehled pravidel přístupu k exportům',
            onClick: () => navigate(EvidenceUrl.EXPORT_ACCESS_RULES),
            href: EvidenceUrl.EXPORT_ACCESS_RULES,
            permission: [Permission.ExportAccessRule.EXPORT_ACCESS_RULE_LIST],
          },
          {
            label: 'Přehled pravidel přístupu k reportům',
            onClick: () => navigate(EvidenceUrl.REPORTING_ACCESS_RULES),
            href: EvidenceUrl.REPORTING_ACCESS_RULES,
            permission: [
              Permission.ReportingAccessRule.REPORTING_ACCESS_RULE_LIST,
            ],
          },
          {
            label: 'Přehled rolí',
            onClick: () => navigate(EvidenceUrl.ROLES),
            href: EvidenceUrl.TODO,
            permission: [Permission.Role.ROLE_LIST],
          },
          {
            label: 'Přehled SOAP komunikace (odchozí)',
            onClick: () => navigate(EvidenceUrl.SOAP_MESSAGES),
            href: EvidenceUrl.SOAP_MESSAGES,
            permission: [Permission.SOAPMessage.WS_CLIENT_MESSAGE_LIST],
          },
          {
            label: 'Přehled systémů třetích stran',
            onClick: () => navigate(EvidenceUrl.THIRD_PARTY_SYSTEMS),
            href: EvidenceUrl.THIRD_PARTY_SYSTEMS,
            permission: [Permission.ThirdPartySystem.THIRD_PARTY_SYSTEM_LIST],
          },
          {
            label: 'Přehled šablon exportu',
            onClick: () => navigate(EvidenceUrl.EXPORT_TEMPLATES),
            href: EvidenceUrl.EXPORT_TEMPLATES,
            permission: [Permission.ExportAccessRule.EXPORT_ACCESS_RULE_LIST],
          },
          {
            label: 'Přehled šablon notifikací',
            onClick: () => navigate(EvidenceUrl.NOTIFICATION_TEMPLATES),
            href: EvidenceUrl.NOTIFICATION_TEMPLATES,
            permission: [Permission.Notification.NOTIFICATION_TEMPLATE_LIST],
          },
          {
            label: 'Přehled volání přijatých z DS',
            onClick: () => navigate(EvidenceUrl.ISPOP_DOCUMENTS),
            href: EvidenceUrl.ISPOP_DOCUMENTS,
            permission: [Permission.IspopDocument.ISPOP_DOCUMENT_LIST],
          },
          {
            label: 'Přehled webových služeb',
            onClick: () => navigate(EvidenceUrl.WEB_SERVICES),
            href: EvidenceUrl.WEB_SERVICES,
            permission: [Permission.WebService.WS_LIST],
          },
          { label: '', separator: true },
          {
            label: 'Běhy pravidelně spouštěných úloh',
            onClick: () => navigate(EvidenceUrl.ADMIN_SCHEDULE_RUNS),
            href: EvidenceUrl.ADMIN_SCHEDULE_RUNS,
            permission: [Permission.ScheduledTask.SCHEDULED_TASK_LIST],
          },
          {
            label: 'Pravidelně spouštěné úlohy',
            onClick: () => navigate(EvidenceUrl.ADMIN_SCHEDULE_JOBS),
            href: EvidenceUrl.ADMIN_SCHEDULE_JOBS,
            permission: [Permission.ScheduledTask.SCHEDULED_TASK_LIST],
          },
        ],
      },
      {
        label: 'Číselníky',
        isActive: (pathname) => {
          return [
            EvidenceUrl.DICTIONARY_IRZ_ACTIVITIES,
            EvidenceUrl.DICTIONARY_EPRTR_ACTIVITIES,
            EvidenceUrl.DICTIONARY_IPPC_ACTIVITIES,
            EvidenceUrl.DICTIONARY_COMPETENT_CONTROL_AUTHORITIES,
            EvidenceUrl.DICTIONARY_COMPETENT_AUTHORITIES,
            EvidenceUrl.DICTIONARY_NACES,
            EvidenceUrl.DICTIONARY_CZ_NACES,
            EvidenceUrl.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS,
            EvidenceUrl.DICTIONARY_RIVER_BASIN_EU_DISTRICTS,
            EvidenceUrl.DICTIONARY_LEGAL_FORMS,
            EvidenceUrl.DICTIONARY_TERRITORIAL_TECHNICAL_UNITS,
            EvidenceUrl.DICTIONARY_PROCESSING_PURPOSE,
            EvidenceUrl.DICTIONARY_BOILER_TYPES,
            EvidenceUrl.DICTIONARY_WATER_TREATMENT_METHODS,
            EvidenceUrl.DICTIONARY_WATER_FLOWS,
            EvidenceUrl.DICTIONARY_INDICATORS,
            EvidenceUrl.DICTIONARY_SAMPLE_TYPES,
            EvidenceUrl.DICTIONARY_SEWER_TYPES,
            EvidenceUrl.DICTIONARY_WATER_ORIGINS,
            EvidenceUrl.DICTIONARY_HYDROGEOLOGICAL_ZONES,
            EvidenceUrl.DICTIONARY_DECISION_TYPES,
            EvidenceUrl.DICTIONARY_RIVER_SIDES,
            EvidenceUrl.DICTIONARY_RUIAN_REGION,
            EvidenceUrl.DICTIONARY_RUIAN_ADDRESS_PLACE,
            EvidenceUrl.DICTIONARY_RUIAN_CADASTRAL_TERRITORY,
            EvidenceUrl.DICTIONARY_RUIAN_DISTRICT,
            EvidenceUrl.DICTIONARY_RUIAN_MUNICIPALITY,
            EvidenceUrl.DICTIONARY_RUIAN_STREET,
            EvidenceUrl.DICTIONARY_RUIAN_DISTRICT_ADMINISTRATIVE_PRAGUE,
            EvidenceUrl.DICTIONARY_RUIAN_CITY_DISTRICT,
            EvidenceUrl.DICTIONARY_RUIAN_CITY_DISTRICT_PRAGUE,
            EvidenceUrl.DICTIONARY_RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY,
            EvidenceUrl.DICTIONARY_RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY,
            EvidenceUrl.DICTIONARY_RUIAN_COHESION_REGION,
            EvidenceUrl.DICTIONARY_RUIAN_MUNICIPALITY_PART,
          ].includes(pathname as EvidenceUrl);
        },
        items: [
          {
            label: 'Přehled',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_INFOS),
            href: EvidenceUrl.DICTIONARY_INFOS,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          { label: '', separator: true },
          {
            label: 'Břehy vodních toků',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_RIVER_SIDES),
            href: EvidenceUrl.DICTIONARY_RIVER_SIDES,
            permission: [Permission.MuvRiverSide.MUV_RIVER_SIDE_LIST],
          },
          {
            label: 'CZ-NACE',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_CZ_NACES),
            href: EvidenceUrl.DICTIONARY_CZ_NACES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Činnosti dle přílohy zákona o IRZ',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_IRZ_ACTIVITIES),
            href: EvidenceUrl.DICTIONARY_IRZ_ACTIVITIES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Činnosti E-PRTR',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_EPRTR_ACTIVITIES),
            href: EvidenceUrl.DICTIONARY_EPRTR_ACTIVITIES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Činnosti IPPC',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_IPPC_ACTIVITIES),
            href: EvidenceUrl.DICTIONARY_IPPC_ACTIVITIES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Druhy rozhodnutí',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_DECISION_TYPES),
            href: EvidenceUrl.DICTIONARY_DECISION_TYPES,
            permission: [Permission.MuvDecisionType.MUV_DECISION_TYPE_LIST],
          },
          {
            label: 'Hydrogeologické rajóny',
            onClick: () =>
              navigate(EvidenceUrl.DICTIONARY_HYDROGEOLOGICAL_ZONES),
            href: EvidenceUrl.DICTIONARY_HYDROGEOLOGICAL_ZONES,
            permission: [
              Permission.MuvHydrogeologicalZone.MUV_HYDROGEOLOGICAL_ZONE_LIST,
            ],
          },
          {
            label: 'Kompetentní kontrolní a ostatní orgány',
            onClick: () =>
              navigate(EvidenceUrl.DICTIONARY_COMPETENT_CONTROL_AUTHORITIES),
            href: EvidenceUrl.DICTIONARY_COMPETENT_CONTROL_AUTHORITIES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Kompetentní orgán - IRZ',
            onClick: () =>
              navigate(EvidenceUrl.DICTIONARY_COMPETENT_AUTHORITIES),
            href: EvidenceUrl.DICTIONARY_COMPETENT_AUTHORITIES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'NACE',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_NACES),
            href: EvidenceUrl.DICTIONARY_NACES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Oblast povodí ČR',
            onClick: () =>
              navigate(EvidenceUrl.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS),
            href: EvidenceUrl.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Oblast povodí Evropy',
            onClick: () =>
              navigate(EvidenceUrl.DICTIONARY_RIVER_BASIN_EU_DISTRICTS),
            href: EvidenceUrl.DICTIONARY_RIVER_BASIN_EU_DISTRICTS,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Právní formy',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_LEGAL_FORMS),
            href: EvidenceUrl.DICTIONARY_LEGAL_FORMS,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Původy odebrané vody',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_WATER_ORIGINS),
            href: EvidenceUrl.DICTIONARY_WATER_ORIGINS,
            permission: [Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_LIST],
          },
          {
            label: 'RUIAN',
            permission: [Permission.Dictionary.DICTIONARY_LIST],
            items: [
              {
                label: 'Adresní místa',
                onClick: () =>
                  navigate(EvidenceUrl.DICTIONARY_RUIAN_ADDRESS_PLACE),
                href: EvidenceUrl.DICTIONARY_RUIAN_ADDRESS_PLACE,
              },
              {
                label: 'Části obce',
                onClick: () =>
                  navigate(EvidenceUrl.DICTIONARY_RUIAN_MUNICIPALITY_PART),
                href: EvidenceUrl.DICTIONARY_RUIAN_MUNICIPALITY_PART,
              },
              {
                label: 'Katastrální území',
                onClick: () =>
                  navigate(EvidenceUrl.DICTIONARY_RUIAN_CADASTRAL_TERRITORY),
                href: EvidenceUrl.DICTIONARY_RUIAN_CADASTRAL_TERRITORY,
              },
              {
                label:
                  'Městský obvod/městská část územně členěného statutárního města (MOMC)',
                onClick: () =>
                  navigate(EvidenceUrl.DICTIONARY_RUIAN_CITY_DISTRICT),
                href: EvidenceUrl.DICTIONARY_RUIAN_CITY_DISTRICT,
              },
              {
                label: 'Městský obvod v hlavním městě Praze (MOP)',
                onClick: () =>
                  navigate(EvidenceUrl.DICTIONARY_RUIAN_CITY_DISTRICT_PRAGUE),
                href: EvidenceUrl.DICTIONARY_RUIAN_CITY_DISTRICT_PRAGUE,
              },
              {
                label: 'Obce',
                onClick: () =>
                  navigate(EvidenceUrl.DICTIONARY_RUIAN_MUNICIPALITY),
                href: EvidenceUrl.DICTIONARY_RUIAN_MUNICIPALITY,
              },
              {
                label: 'Okresy',
                onClick: () => navigate(EvidenceUrl.DICTIONARY_RUIAN_DISTRICT),
                href: EvidenceUrl.DICTIONARY_RUIAN_DISTRICT,
              },
              {
                label: 'Regiony soudržnosti',
                onClick: () =>
                  navigate(EvidenceUrl.DICTIONARY_RUIAN_COHESION_REGION),
                href: EvidenceUrl.DICTIONARY_RUIAN_COHESION_REGION,
              },
              {
                label: 'Správní obvody obcí s pověřeným obecním úřadem (POU)',
                onClick: () =>
                  navigate(
                    EvidenceUrl.DICTIONARY_RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY
                  ),
                href:
                  EvidenceUrl.DICTIONARY_RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY,
              },
              {
                label: 'Správní obvod obce s rozšířenou působností (ORP)',
                onClick: () =>
                  navigate(
                    EvidenceUrl.DICTIONARY_RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY
                  ),
                href:
                  EvidenceUrl.DICTIONARY_RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY,
              },
              {
                label: 'Správní obvody v hlavním městě Praze',
                onClick: () =>
                  navigate(
                    EvidenceUrl.DICTIONARY_RUIAN_DISTRICT_ADMINISTRATIVE_PRAGUE
                  ),
                href:
                  EvidenceUrl.DICTIONARY_RUIAN_DISTRICT_ADMINISTRATIVE_PRAGUE,
              },
              {
                label: 'Ulice',
                onClick: () => navigate(EvidenceUrl.DICTIONARY_RUIAN_STREET),
                href: EvidenceUrl.DICTIONARY_RUIAN_STREET,
              },
              {
                label: 'Vyšší územní samoprávní celek (nový kraj)',
                onClick: () => navigate(EvidenceUrl.DICTIONARY_RUIAN_REGION),
                href: EvidenceUrl.DICTIONARY_RUIAN_REGION,
              },
            ],
          },
          {
            label: 'Státy',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_COUNTRIES),
            href: EvidenceUrl.DICTIONARY_COUNTRIES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Typy kanalizace',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_SEWER_TYPES),
            href: EvidenceUrl.DICTIONARY_SEWER_TYPES,
            permission: [Permission.MuvSewerType.MUV_SEWER_TYPE_LIST],
          },
          {
            label: 'Typy kotlů',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_BOILER_TYPES),
            href: EvidenceUrl.DICTIONARY_BOILER_TYPES,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Typy vzorků',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_SAMPLE_TYPES),
            href: EvidenceUrl.DICTIONARY_SAMPLE_TYPES,
            permission: [Permission.MuvSampleType.MUV_SAMPLE_TYPE_LIST],
          },
          {
            label: 'Účely zpracování',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_PROCESSING_PURPOSE),
            href: EvidenceUrl.DICTIONARY_PROCESSING_PURPOSE,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Ukazetele VHR',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_INDICATORS),
            href: EvidenceUrl.DICTIONARY_INDICATORS,
            permission: [Permission.MuvIndicator.MUV_INDICATOR_LIST],
          },
          {
            label: 'Územně technická jednotka (ÚTJ)',
            onClick: () =>
              navigate(EvidenceUrl.DICTIONARY_TERRITORIAL_TECHNICAL_UNITS),
            href: EvidenceUrl.DICTIONARY_TERRITORIAL_TECHNICAL_UNITS,
            permission: [Permission.Dictionary.DICTIONARY_LIST],
          },
          {
            label: 'Vodní toky',
            onClick: () => navigate(EvidenceUrl.DICTIONARY_WATER_FLOWS),
            href: EvidenceUrl.DICTIONARY_WATER_FLOWS,
            permission: [Permission.MuvWaterFlow.MUV_WATER_FLOW_LIST],
          },
          {
            label: 'Způsoby úpravy vody',
            onClick: () =>
              navigate(EvidenceUrl.DICTIONARY_WATER_TREATMENT_METHODS),
            href: EvidenceUrl.DICTIONARY_WATER_TREATMENT_METHODS,
            permission: [
              Permission.MuvWaterTreatment.MUV_WATER_TREATMENT_METHOD_LIST,
            ],
          },
        ],
      },
    ],
    [navigate]
  );

  const crzpDeveloperToolItems: CrzpMenuItem[] = useMemo(
    () => [
      {
        label: 'Číselné řady',
        onClick: () => navigate(EvidenceUrl.SEQUENCES),
        href: EvidenceUrl.SEQUENCES,
        permission: [],
      },
      {
        label: 'Skripty',
        onClick: () => navigate(EvidenceUrl.ADMIN_ACTIONS),
        href: EvidenceUrl.ADMIN_ACTIONS,
        permission: [Permission.Action.ACTIONS_LIST],
      },
      {
        label: 'SOAP - příchozí zprávy',
        onClick: () => navigate(EvidenceUrl.SOAP_MESSAGE_LOGS),
        href: EvidenceUrl.SOAP_MESSAGE_LOGS,
        permission: [Permission.Default.ADMINISTRATOR],
      },
      {
        label: 'Exporty',
        onClick: () => navigate(EvidenceUrl.EXPORT_REQUESTS),
        href: EvidenceUrl.EXPORT_REQUESTS,
        permission: [],
      },
    ],
    [navigate]
  );

  return {
    items,
    crzpDeveloperToolItems,
  };
};
