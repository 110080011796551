import * as Yup from 'yup';
import {
  RoleInstance,
  RoleInstanceType,
  Role,
  User,
  Subject,
} from '../../models';
import { System } from '../../models/system';

export const iczFormat = /^(CZ|CS)[ASULKHEPCJBMTZ][0-9]{5}$/g;
export const icpFormat = /^(?!(\s+|CZ|CS|CO|0+$))\w{1,12}(?!\s+)$/g;

export function useValidationSchema() {
  return Yup.object<RoleInstance>().shape({
    type: Yup.mixed<RoleInstanceType>().nullable().required('Povinné pole'),
    role: Yup.mixed<Role>().nullable().required('Povinné pole'),
    user: Yup.mixed<User>().when('type', {
      is: (type) =>
        type === RoleInstanceType.RELATIONSHIP_ROLE_INSTANCE ||
        type === RoleInstanceType.SECURITY_ROLE_INSTANCE ||
        type === RoleInstanceType.USER_ROLE_INSTANCE,
      then: Yup.mixed<User>().nullable().required('Povinné pole'),
    }),
    subject: Yup.mixed<Subject>().when('type', {
      is: (type) => type === RoleInstanceType.RELATIONSHIP_ROLE_INSTANCE,
      then: Yup.mixed<Subject>().nullable().required('Povinné pole'),
    }),
    system: Yup.mixed<System>().when('type', {
      is: (type) => type === RoleInstanceType.SYSTEM_ROLE_INSTANCE,
      then: Yup.mixed<System>().nullable().required('Povinné pole'),
    }),
    sepnoDetails: validateSepnoDetails,
  }) as Yup.Schema<RoleInstance>;
}

export const validateSepnoDetails = Yup.object().shape({
  icps: Yup.array().of(
    Yup.object<{ name: string }>().test('', '', function (val) {
      const { createError } = this;

      if (!val?.name || !val.name.match(icpFormat)) {
        return createError({
          path: 'sepnoDetails.icps',
          message:
            'Číslo provozovny může mít maximálně 12 alfanumerických znaků (nesmí nabývat hodnoty 0), nesmí začínat ani končit mezerou nebo řadou mezer, nesmí začínat písmeny CZ, CS ani CO',
        });
      }

      return true;
    })
  ),
  iczs: Yup.array().of(
    Yup.object<{ name: string }>().test('', '', function (val) {
      const { createError } = this;

      if (!val?.name || !val.name.match(iczFormat)) {
        return createError({
          path: 'sepnoDetails.iczs',
          message:
            'Musí obsahovat právě 8 znaků, formát CZXYYYYY nebo CSXYYYYY; kde X (x=1) je písmeno a smí nabývat pouze hodnot "A; S; U; L; K; H; E; P; C; J; B; M; T; Z" a YYYYY je pořadové číslo evidovaného zařízení smí nabývat hodnot 0 - 9 (y=5)',
        });
      }

      return true;
    })
  ),
});
