import { HistoryFieldDescriptor } from '../../components/evidence/history-evidence/history-types';
import { useRoleInstanceTypes } from './role-instances-api';
import { useUsers } from '../user/users-api';
import { useSystems } from '../system/systems-api';

export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const types = useRoleInstanceTypes();
  const users = useUsers();
  const systems = useSystems();

  return [
    {
      key: 'active',
      label: 'Aktivní',
    },
    {
      key: 'validFrom',
      label: 'Platnost od',
    },
    {
      key: 'validTo',
      label: 'Platnost do',
    },
    {
      key: 'type',
      label: 'Typ',
      source: types,
    },
    {
      key: 'role',
      label: 'Role',
    },
    {
      key: 'user',
      label: 'Uživatel',
      source: users,
    },
    {
      key: 'subject',
      label: 'Subjekt',
    },
    {
      key: 'system',
      label: 'Systém',
      source: systems,
    },
    {
      key: 'sepnoDetails.icps',
      label: 'SEPNO detaily - IČP',
    },
    {
      key: 'sepnoDetails.iczs',
      label: 'SEPNO detaily - IČZ',
    },
  ];
}
