import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryWaterOrigin } from '../../models';
import { useValidationSchema } from './dict-water-origins-schema';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';

export function DictionaryWaterOrigins() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<DictionaryWaterOrigin>({
    options: {
      NEW: [Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_CREATE],
      EDIT: [Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_UPDATE],
      REMOVE: [Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_DELETE],
      CHECK: [
        Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_CREATE,
        Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_UPDATE,
      ],
      SAVE: [
        Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_CREATE,
        Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_UPDATE,
      ],
      CLOSE: [
        Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_CREATE,
        Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_UPDATE,
      ],
    },
  });

  const evidence = useDictionaryEvidence<DictionaryWaterOrigin>(
    {
      identifier: PageKey.DICTIONARY_WATER_ORIGINS,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_WATER_ORIGINS,
      },
      tableProps: {
        tableName: 'Původy odebrané vody',
        reportTag: ExportTags.DICTIONARY_WATER_ORIGINS,
        defaultSorts: [],
      },
      detailProps: {
        codePrefix: 'WATER_ORIGIN',
        validationSchema,
        toolbarProps: {
          title: 'Původ odebrané vody',
          showBtn: permissions,
        },
      },
    },
    Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_INVALIDATE,
    Permission.MuvWaterOrigin.MUV_WATER_ORIGIN_REVALIDATE
  );

  return <Evidence2 {...evidence} />;
}
