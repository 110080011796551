import React from 'react';
import { Evidence2, EmptyComponent } from '@eas/common-web';
import { DictionaryBoilerModel } from '../../models';
import { DictionaryBoilerModelsFields } from './dict-boiler-models-fields';
import { useValidationSchema } from './dict-boiler-models-schema';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { useColumns } from './dict-boiler-models-columns';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useHistoryDescriptors } from './dict-boiler-models-history';

export function DictionaryBoilerModels() {
  const validationSchema = useValidationSchema();
  const permissions = usePermission({
    options: {
      NEW: [],
      CHECK: [],
      EDIT: [],
      REMOVE: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<DictionaryBoilerModel>({
    identifier: PageKey.DICTIONARY_BOILER_MODELS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_BOILER_MODELS,
    },
    tableProps: {
      tableName: 'Přehled modelů kotlů',
      columns: useColumns(),
      reportTag: ExportTags.DICTIONARY_BOILER_MODELS,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: DictionaryBoilerModelsFields,
      validationSchema,
      toolbarProps: {
        title: 'Model kotle',
        subtitle: useSubTitle<DictionaryBoilerModel>(
          (data) => data?.name ?? ''
        ),
        showBtn: permissions,
        ActionBar: EmptyComponent,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Ozo.OZO_MODEL_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}
