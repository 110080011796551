import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { noop } from 'lodash';
import {
  Checkbox,
  DetailContext,
  FormCheckboxGroup,
  FormContext,
  FormCustomField,
  FormSelect,
  FormTextField,
  useFormSelector,
  UserContext,
  useStaticListSource,
  useEventCallback,
} from '@eas/common-web';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useRoleRelationships } from '../../../roles/roles-api';
import { useSubjectRoles } from '../../subjects-api';
import {
  Role,
  SystemAutocomplete,
  Me,
  RelationshipType,
  RoleType,
  UserRole,
  SepnoDetails,
} from '../../../../models';
import { Permission } from '../../../../enums';
import { DialogSepnoDetails } from '../../../role-instance/fields/sepno-details-fields';

export const useStyles = makeStyles({
  icon: {
    marginRight: '2pt',
    padding: 1,
  },
  iconOpened: {
    transform: 'rotate(180deg)',
  },
  system: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  padding: {
    paddingTop: 5,
  },
  border: {
    borderBottom: '1px solid #cdcdcd',
  },
  systemWrapper: {
    borderBottom: '1px solid #cdcdcd',
  },
  labelWrapper: {
    display: 'flex',
    gap: '5px',
  },
  roles: {
    marginLeft: 25,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
  },
  rolesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  warning: {
    fontWeight: 600,
    marginTop: -20,
    marginBottom: 20,
  },
});

export function AssignUserDialog() {
  const classes = useStyles();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { source } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  const relationships = useRoleRelationships();
  const { availableSystems, roles } = useSubjectRoles(source.data!);

  const hasUserRoles = !!roles.filter(
    (role) => role.type === RoleType.USER_ROLE
  ).length;

  const { relationship, userRoleSepnoDetails } = useFormSelector(
    (values: {
      relationship: RelationshipType;
      userRoles: UserRole[];
      userRoleSepnoDetails: SepnoDetails & { role: Role }[];
    }) => ({
      relationship: values.relationship,
      userRoleSepnoDetails: values.userRoleSepnoDetails ?? [],
    })
  );

  const hasOnlyAdminCanAssignRoles = roles.find(
    (role) => role.onlyAdminCanAssign
  );

  const notAutoAssignableToAdminRoles = roles.filter(
    (role) =>
      role.type === RoleType.USER_ROLE &&
      !(role as UserRole).autoAssignToSubjectAdmin
  );

  const canAssignAdminRoles =
    hasPermission(Permission.Role.ROLE_ASSIGN_USER_ROLE) ||
    hasPermission(Permission.User.USER_ASSIGN_ALL, {
      subjectId: source?.data?.id,
    });

  const { roles: onlyAdminCanAssignSubjectRoles } = useSubjectRoles(
    source.data!,
    true
  );

  const adminAssignableRoleSource = useStaticListSource(
    canAssignAdminRoles
      ? [...onlyAdminCanAssignSubjectRoles, ...notAutoAssignableToAdminRoles]
      : notAutoAssignableToAdminRoles
  );

  const handleUserRolesChange = useEventCallback((value: unknown) => {
    const roles = value as UserRole[];
    const sepnoRoles = roles?.filter((role) => role?.allowSepnoDetails);

    const autoAssignableSepnoDetails = userRoleSepnoDetails.filter(
      (detail) => (detail.role as UserRole).autoAssignToSubjectAdmin
    );
    const nonAutoAssignableSepnoDetails = userRoleSepnoDetails.filter(
      (detail) => !(detail.role as UserRole).autoAssignToSubjectAdmin
    );

    if (sepnoRoles?.length !== nonAutoAssignableSepnoDetails.length) {
      const originalRoleIds = userRoleSepnoDetails.map(
        (detail) => detail.role.id
      );
      const currentRoleIds = sepnoRoles.map((role) => role.id);

      setFieldValue('userRoleSepnoDetails', [
        ...autoAssignableSepnoDetails,
        ...userRoleSepnoDetails.filter((detail) =>
          currentRoleIds.includes(detail.role.id)
        ),
        ...sepnoRoles
          ?.filter((role) => !originalRoleIds.includes(role.id))
          .map((role) => ({
            role: role,
          })),
      ]);
    }
  });

  return (
    <>
      <Typography className={classes.warning}>
        Upozornění: Je nutné vyplnit uživatelské jméno uživatele, který již
        provedl registraci a disponuje vlastním uživatelským jménem!
      </Typography>
      <FormTextField
        name="username"
        label="Uživatelské jméno"
        autocomplete="off"
      />
      <FormSelect
        name="relationship"
        label="Vztah"
        source={relationships}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
        notifyChange={(value) => {
          setFieldValue('userRoles', undefined);
          if (value === RelationshipType.ADMINISTRATOR) {
            setFieldValue(
              'userRoleSepnoDetails',
              roles
                .filter(
                  (role) =>
                    role.allowSepnoDetails &&
                    (role as UserRole).autoAssignToSubjectAdmin
                )
                .map((role) => ({ role: role }))
            );
          } else {
            setFieldValue('userRoleSepnoDetails', []);
          }
        }}
      />
      {relationship === RelationshipType.USER && hasUserRoles && (
        <Systems systems={availableSystems} roles={roles} />
      )}
      {relationship === RelationshipType.ADMINISTRATOR &&
        (hasOnlyAdminCanAssignRoles ||
          notAutoAssignableToAdminRoles.length > 0) && (
          <FormCheckboxGroup
            name="userRoles"
            label="Role"
            source={adminAssignableRoleSource}
            valueIsId={false}
            notifyChange={handleUserRolesChange}
          />
        )}
      {userRoleSepnoDetails?.length > 0 && <DialogSepnoDetails />}
    </>
  );
}

export function Systems({
  systems,
  roles,
}: {
  systems: SystemAutocomplete[];
  roles: Role[];
}) {
  const classes = useStyles();

  const { userRoles } = useFormSelector((data: { userRoles: Role[] }) => ({
    userRoles: data.userRoles ?? [],
  }));

  return (
    <FormCustomField label="Role" layoutOptions={{ noUnderline: true }}>
      <div className={classes.rolesWrapper}>
        {systems.map((system) => (
          <SystemRoles
            system={system}
            key={system.id}
            checked={
              !!userRoles.find(
                (role) => role.registeredFor?.code === system?.code
              )
            }
            roles={roles.filter(
              (role) => role.registeredFor?.code === system?.code
            )}
          />
        ))}
      </div>
    </FormCustomField>
  );
}

function SystemRoles({
  system,
  checked,
  roles,
}: {
  system: SystemAutocomplete;
  checked: boolean;
  roles: Role[];
}) {
  const classes = useStyles();
  const { setFieldValue } = useContext(FormContext);

  const [showRoles, setShowRoles] = useState(false);

  const roleSource = useStaticListSource(roles);

  const { userRoleSepnoDetails } = useFormSelector(
    (data: { userRoleSepnoDetails: SepnoDetails & { role: Role }[] }) => ({
      userRoleSepnoDetails: data.userRoleSepnoDetails ?? [],
    })
  );

  const handleUserRolesChange = useEventCallback((value: unknown) => {
    const roles = value as UserRole[];
    const sepnoRoles = roles?.filter((role) => role?.allowSepnoDetails);

    if (sepnoRoles?.length !== userRoleSepnoDetails?.length) {
      const originalRoleIds = userRoleSepnoDetails.map(
        (detail) => detail.role.id
      );
      const currentRoleIds = sepnoRoles.map((role) => role.id);

      setFieldValue('userRoleSepnoDetails', [
        ...userRoleSepnoDetails.filter((detail) =>
          currentRoleIds.includes(detail.role.id)
        ),
        ...sepnoRoles
          ?.filter((role) => !originalRoleIds.includes(role.id))
          .map((role) => ({
            role: role,
          })),
      ]);
    }
  });

  return (
    <div className={classes.systemWrapper}>
      <span
        className={classes.system}
        onClick={() => setShowRoles((prev) => !prev)}
      >
        <span className={classes.labelWrapper}>
          <Checkbox value={checked} onChange={noop} disabled={true} />
          <Typography variant="body2" className={classes.label}>
            {system.name}
          </Typography>
        </span>
        <IconButton
          size="small"
          classes={{
            root: clsx(classes.icon, {
              [classes.iconOpened]: showRoles,
            }),
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowRoles((prev) => !prev);
          }}
        >
          <ArrowDropDownIcon fontSize="small" />
        </IconButton>
      </span>
      {showRoles && (
        <div className={classes.roles}>
          <FormCheckboxGroup
            name="userRoles"
            labelOptions={{ hide: true }}
            layoutOptions={{ noUnderline: true }}
            source={roleSource}
            valueIsId={false}
            notifyChange={handleUserRolesChange}
          />
        </div>
      )}
    </div>
  );
}
