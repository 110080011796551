import { useContext, useMemo, useRef } from 'react';
import {
  useEventCallback,
  SnackbarContext,
  DetailContext,
  UserContext,
  DetailHandle,
  abortableFetch,
  AbortableFetch,
  PromptContext,
  usePrompts,
} from '@eas/common-web';
import { Permission, EvidenceAPI, Messages } from '../../../enums';
import { OvzFacilityRequest } from '../../../models';
import { unstable_batchedUpdates } from 'react-dom';
import { OvzFacilityRequestState } from '../../../models/ovz-facility-request';

/**
 * Api call
 * @param id
 */
export function apiCall(id: string) {
  return abortableFetch(`${EvidenceAPI.OVZ_FACILITY_REQUESTS}/${id}/reject`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}

const PROMPT_KEY = 'REJECT';

export function useRejectDialog() {
  /**
   * Context stuff.
   */
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, onPersisted } = useContext<DetailHandle<OvzFacilityRequest>>(
    DetailContext
  );
  const { hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialogs
   */
  usePrompts([
    {
      key: PROMPT_KEY,
      dialogTitle: 'Zamítnutí nabídky',
      dialogText: 'Skutečně chcete zamítnout nabídku provozovny OVZ?',
    },
  ]);

  const handleReject = useEventCallback(() => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async () => {
        if (source.data) {
          try {
            source.setLoading(true);
            if (fetch.current !== null) {
              fetch.current.abort();
            }
            fetch.current = apiCall(source.data.id);

            await fetch.current.raw();

            unstable_batchedUpdates(() => {
              showSnackbar(...Messages.OvzFacilityRequest.REJECT.SUCCESS);
              source.setLoading(false);
            });

            await source.refresh();
            onPersisted(source.data.id);
          } catch (err) {
            source.setLoading(false);
            if (err.name !== 'AbortError') {
              showSnackbar(...Messages.OvzFacilityRequest.REJECT.ERROR);
              throw err;
            }
            return undefined;
          }
        }
      },
    });
  });

  const canFinish = useMemo(() => {
    return (
      hasPermission(
        Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_FINISH_ALL
      ) ||
      hasPermission(
        Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_FINISH_OWN,
        {
          subjectId: source.data?.newOperator?.id,
        }
      )
    );
  }, [source.data?.newOperator?.id, hasPermission]);

  const showRejectButton =
    canFinish &&
    source.data?.state === OvzFacilityRequestState.WAITING_FOR_AUTHORIZATION;

  return {
    showRejectButton,
    handleReject,
  };
}
