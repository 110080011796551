import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryIndicator } from '../../models';
import { useValidationSchema } from './dict-indicators-schema';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { DictionaryIndicatorsFields } from './dict-indicators-fields';
import { usePermission } from '../../components/permission/perm-evidence-hook';

export function DictionaryIndicators() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<DictionaryIndicator>({
    options: {
      NEW: [Permission.MuvIndicator.MUV_INDICATOR_CREATE],
      EDIT: [Permission.MuvIndicator.MUV_INDICATOR_UPDATE],
      REMOVE: [Permission.MuvIndicator.MUV_INDICATOR_DELETE],
      CHECK: [
        Permission.MuvIndicator.MUV_INDICATOR_CREATE,
        Permission.MuvIndicator.MUV_INDICATOR_UPDATE,
      ],
      SAVE: [
        Permission.MuvIndicator.MUV_INDICATOR_CREATE,
        Permission.MuvIndicator.MUV_INDICATOR_UPDATE,
      ],
      CLOSE: [
        Permission.MuvIndicator.MUV_INDICATOR_CREATE,
        Permission.MuvIndicator.MUV_INDICATOR_UPDATE,
      ],
    },
  });

  const evidence = useDictionaryEvidence<DictionaryIndicator>(
    {
      identifier: PageKey.DICTIONARY_INDICATORS,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_INDICATORS,
      },
      tableProps: {
        tableName: 'Ukazatele VHR',
        reportTag: ExportTags.DICTIONARY_INDICATORS,
        defaultSorts: [],
      },
      detailProps: {
        codePrefix: 'INDICATOR',
        validationSchema,
        GeneralFieldsComponent: DictionaryIndicatorsFields,
        toolbarProps: {
          title: 'Ukazatel VHR',
          showBtn: permissions,
        },
      },
    },
    Permission.MuvIndicator.MUV_INDICATOR_INVALIDATE,
    Permission.MuvIndicator.MUV_INDICATOR_REVALIDATE
  );

  return <Evidence2 {...evidence} />;
}
