import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { EvidenceAPI, PageKey } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { DictionarySequence } from '../../models';
import { useValidationSchema } from './dict-sequences-schema';
import { useColumns } from './dict-sequences-columns';
import { SequencesFields } from './dict-sequences-fields';

export function DictionarySequences() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionarySequence>({
    identifier: PageKey.DICTIONARY_SEQUENCES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_SEQUENCES,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Číselné řady',
      reportTag: ExportTags.DICTIONARY_SEQUENCES,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'SEQUENCE',
      FieldsComponent: SequencesFields,
      validationSchema,
      toolbarProps: {
        title: 'Číselná řada',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
