import React, { useState, Dispatch, SetStateAction, useContext } from 'react';
import MapIcon from '@material-ui/icons/Map';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import {
  AuthoredObject,
  EvidenceProps,
  useEventCallback,
  DetailToolbarButtonType,
  EvidenceContext,
  EmptyComponent,
  DetailContext,
  DetailMode,
  PanelHandle,
  DetailActionbarButton,
} from '@eas/common-web';
import { useAuthoredEvidence } from '../authored-evidence/authored-evidence';
import { MapEvidenceView } from './map-context';
import { DatedEvidenceProps } from '../dated-evidence/dated-types';
import { MapEvidenceProps } from './map-types';
import { DetailToolbarButton } from '../evidence/detail-toolbar-button';
import { DetailToolbar } from '../evidence/detail-toolbar';
import { useExpandableFormPanel } from '../../../composite/form-panel/expandable-form-panel-hook';

export function useMapEvidence<OBJECT extends AuthoredObject>(
  options: MapEvidenceProps<OBJECT>
): {
  evidence: EvidenceProps<OBJECT>;
  context: {
    view: MapEvidenceView;
    setView: Dispatch<SetStateAction<MapEvidenceView>>;
    withFilters: boolean;
    addressPanelRef: React.RefObject<PanelHandle>;
    locationPanelRef: React.RefObject<PanelHandle>;
  };
} {
  /**
   * Map layer in Evidence/Detail.
   */
  const [view, setView] = useState<MapEvidenceView>(MapEvidenceView.DETAIL);

  const [withFilters, setWithFilters] = useState<boolean>(false);

  /**
   * MAP ToolbarButton handler.
   */
  const handleMapBtnClick = useEventCallback(() => {
    setView((v) =>
      v === MapEvidenceView.DETAIL
        ? MapEvidenceView.MAP
        : MapEvidenceView.DETAIL
    );
  });

  /**
   * Switch default FieldsComponent when view === MAP.
   */
  const FieldsComponent =
    view === MapEvidenceView.DETAIL
      ? options.detailProps?.FieldsComponent
      : options.detailProps?.MapComponent;

  /**
   * Map view.
   */
  const map: DatedEvidenceProps<OBJECT> = {
    ...options,
    switcherProps: {
      leftLabel: 'Přehled',
      rightLabel: 'Mapa',
      hideMenuTools: true,
    },
    detailProps: {
      ...options.detailProps,
      hideGeneralFields: true,
      toolbarProps: {
        ...options.detailProps?.toolbarProps,
        After: function After() {
          const { mode } = useContext(DetailContext);

          return (
            <>
              {view === MapEvidenceView.DETAIL &&
                (mode === DetailMode.VIEW || mode === DetailMode.NONE) && (
                  <DetailToolbarButton
                    startIcon={<MapIcon />}
                    label="Mapa"
                    onClick={handleMapBtnClick}
                  />
                )}
              {view === MapEvidenceView.MAP && (
                <DetailToolbarButton
                  startIcon={<VerticalSplitIcon />}
                  label="Formulář"
                  onClick={handleMapBtnClick}
                />
              )}
              {options.detailProps?.toolbarProps?.After && (
                <options.detailProps.toolbarProps.After />
              )}
            </>
          );
        },
        ActionBar: (function useActionBar() {
          if (view === MapEvidenceView.DETAIL) {
            return (
              options.detailProps?.toolbarProps?.ActionBar ?? EmptyComponent
            );
          }

          return function Bar() {
            const ctx = useContext(EvidenceContext);

            return (
              <>
                <DetailActionbarButton
                  startIcon={<TuneIcon />}
                  label="Aplikovat filtry z přehledu"
                  onClick={() => {
                    setWithFilters(true);
                    setTimeout(() => ctx.detailRef.current?.refresh(), 0);
                  }}
                  type={DetailToolbarButtonType.PRIMARY}
                />
                <DetailActionbarButton
                  startIcon={<CloseIcon />}
                  label="Zrušit filtry"
                  onClick={() => {
                    setWithFilters(false);
                    setTimeout(() => ctx.detailRef.current?.refresh(), 0);
                  }}
                  disabled={!withFilters}
                  type={DetailToolbarButtonType.SECONDARY}
                />
              </>
            );
          };
        })(),
      },
      ToolbarComponent: DetailToolbar,
      FieldsComponent,
    },
    tableProps: {
      ...options.tableProps,
    },
  };

  /**
   * Evidence view.
   */
  const evidence = useAuthoredEvidence(map);

  const { ref: locationPanelRef } = useExpandableFormPanel();
  const { ref: addressPanelRef } = useExpandableFormPanel();

  return {
    evidence: view === MapEvidenceView.DETAIL ? evidence : map,
    context: { view, setView, withFilters, locationPanelRef, addressPanelRef },
  };
}
