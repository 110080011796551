import React from 'react';
import { AdministrativeDistrictPrague } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';

import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';
import DictionaryRuianAdministrativeDistrictPragueFields from './dict-ruian-district-administrative-prague-fields';

export function DictionaryRuianDistrictAdministrativePrague() {
  const evidence = useRuianEvidence<AdministrativeDistrictPrague>(
    {
      identifier: PageKey.DICTIONARY_RUIAN_DISTRICT_ADMINISTRATIVE_PRAGUE,
      apiProps: {
        url: EvidenceAPI.ADDRESS_ADMINISTRATIVE_DISTRICT_PRAGUE,
      },
      tableProps: {
        tableName: 'RUIAN - Správní obvody v Praze',
        reportTag: ExportTags.DICTIONARY_DISTRICT_ADMINISTRATION_PRAGUE,
      },
      detailProps: {
        FieldsComponent: DictionaryRuianAdministrativeDistrictPragueFields,
        toolbarProps: {
          title: 'RUIAN - Správní obvod v hlavním městě Praze',
        },
      },
    },
    {
      hasMunicipality: true,
    }
  );

  return <Evidence2 {...evidence} />;
}
