import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryIrzActivity } from '../../models';
import { useValidationSchema } from './dict-irz-activities-schema';
import { EvidenceAPI, PageKey } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';

export function DictionaryIrzActivities() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryIrzActivity>({
    identifier: PageKey.DICTIONARY_IRZ_ACTIVITIES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_IRZ_ACTIVITIES,
    },
    tableProps: {
      tableName: 'Činnosti dle př. zákona o IRZ a ISPOP',
      reportTag: ExportTags.DICTIONARY_IRZ_ACTIVITIES,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'IRZ_ACTIVITY',
      validationSchema,
      toolbarProps: {
        title: 'Činnost dle př. zákona o IRZ a ISPOP',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
