import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { useValidationSchema } from './dict-countries-schema';
import { DictionaryCountry } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { DictionaryCountryFields } from './dict-countries-fields';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';

export function DictionaryCountries() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryCountry>({
    identifier: PageKey.DICTIONARY_COUNTRIES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_COUNTRIES,
    },
    tableProps: {
      tableName: 'Státy',
      reportTag: ExportTags.DICTIONARY_COUNTRIES,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'COUNTRY',
      validationSchema,
      GeneralFieldsComponent: DictionaryCountryFields,
      toolbarProps: {
        title: 'Stát',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
