import React from 'react';
import { Street } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useColumns } from './dict-ruian-street-columns';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';

export function DictionaryRuianStreet() {
  const evidence = useRuianEvidence<Street>(
    {
      identifier: PageKey.DICTIONARY_RUIAN_STREET,
      apiProps: {
        url: EvidenceAPI.ADDRESS_STREET,
      },
      tableProps: {
        columns: useColumns(),
        tableName: 'RUIAN - Ulice',
        reportTag: ExportTags.DICTIONARY_STREET,
      },
      detailProps: {
        toolbarProps: {
          title: 'RUIAN - Ulice',
        },
      },
    },
    {
      hasMunicipality: true,
    }
  );

  return <Evidence2 {...evidence} />;
}
