import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Evidence2 } from '@eas/common-web';
import { WaterManagementDecisionPart, Exception } from '../../models';
import { useColumns } from './water-management-decision-parts-columns';
import { WaterManagementDecisionPartFields } from './water-management-decision-parts-fields';
import { EvidenceAPI, PageKey, Permission, Messages } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { useHistoryDescriptors } from './water-management-decision-parts-history';
import { useValidationSchema } from './water-management-decision-parts-schema';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';
import { createItemFactory, updateItemFactory } from '../../utils/custom-crud';
import { getErrorMessage } from '../../utils/get-message';

export function WaterManagementDecisionParts() {
  const permissions = usePermission({
    options: {
      NEW: [Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_CREATE],
      EDIT: [Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_UPDATE],
      REMOVE: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_DELETE,
      ],
      CHECK: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_CREATE,
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_UPDATE,
      ],
      SAVE: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_CREATE,
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_UPDATE,
      ],
      CLOSE: [
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_CREATE,
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_UPDATE,
      ],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<WaterManagementDecisionPart>({
    version: 11,
    identifier: PageKey.WATER_MANAGEMENT_DECISION_PARTS,
    apiProps: {
      url: EvidenceAPI.WATER_MANAGEMENT_DECISION_PARTS,
      createItem: createItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.WaterManagementDecisionPart.CREATE,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
      updateItem: updateItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.WaterManagementDecisionPart.UPDATE,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled částí rozhodnutí MUV',
      reportTag: ExportTags.WATER_MANAGEMENT_DECISION_PARTS,
      defaultSorts: [],
      showColumn: (column) => column === 'CREATED' || column === 'UPDATED',
    },
    detailProps: {
      validationSchema: useValidationSchema(),
      FieldsComponent: WaterManagementDecisionPartFields,
      initNewItem: () => ({
        id: uuidv4(),
        active: true,
      }),
      toolbarProps: {
        title: 'Část rozhodnutí MUV',
        ActionBar: DetailEmptyActionbar,
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission:
        Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_PART_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}
