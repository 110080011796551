import React from 'react';
import { Evidence2, UserContext } from '@eas/common-web';
import { IrzFacility, Me, PermissionsState, Exception } from '../../models';
import { IrzFacilitiesFields } from './irz-facilities-fields';
import { useColumns } from './irz-facilities-columns';
import { useValidationSchema } from './irz-facilities-schema';
import { useMapEvidence } from '../../components/evidence/map-evidence/map-evidence';
import { IrzFacilitiesMap } from './irz-facilities-map';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  IncludeOptions,
  Messages,
} from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { ExportTags } from '../../enums/export-tags';
import { IrzFacilitiesToolbar } from './irz-facilities-toolbar';
import { combineGetCalls } from './irz-facilities-api';
import {
  getItemFactory,
  updateItemFactory,
  createItemFactory,
} from '../../utils/custom-crud';
import { useMemo, useContext } from 'react';
import { EntityType } from '../../enums/entity-type';
import { IrzFacilitiesActionbar } from './irz-facilities-actionbar';
import { MapEvidenceContext } from '../../components/evidence/map-evidence/map-context';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useSpecialFilters } from './irz-facilities-special-filters';
import { IrzFacilitiesTableToolbar } from './irz-facilities-table-toolbar';
import { useHistoryDescriptors } from './irz-facilities-history';

export function IrzFacilities() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<IrzFacility>({
    options: {
      NEW: [Permission.IrzFacility.IRZ_CREATE],
      EDIT: [
        Permission.IrzFacility.IRZ_UPDATE,
        Permission.IrzFacility.IRZ_UPDATE_NON_APPROVED_FIELDS,
      ],
      REMOVE: [],
      CHECK: [
        Permission.IrzFacility.IRZ_CREATE,
        Permission.IrzFacility.IRZ_UPDATE,
        Permission.IrzFacility.IRZ_UPDATE_NON_APPROVED_FIELDS,
      ],
      SAVE: [
        Permission.IrzFacility.IRZ_CREATE,
        Permission.IrzFacility.IRZ_UPDATE,
        Permission.IrzFacility.IRZ_UPDATE_NON_APPROVED_FIELDS,
      ],
      CLOSE: [
        Permission.IrzFacility.IRZ_CREATE,
        Permission.IrzFacility.IRZ_UPDATE,
        Permission.IrzFacility.IRZ_UPDATE_NON_APPROVED_FIELDS,
      ],
    },
  });
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const canSeeDeactivated = useMemo(
    () =>
      hasPermission(Permission.IrzFacility.IRZ_INVALIDATE) ||
      hasPermission(Permission.IrzFacility.IRZ_REQUEST_INVALIDATE_SUBMIT, {
        searchInSubjectPerms: true,
      }),
    [hasPermission]
  );

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  const historyDescriptors = useHistoryDescriptors();

  const { evidence, context } = useMapEvidence<IrzFacility>({
    version: 3,
    identifier: PageKey.IRZ_FACILITIES,
    noteEntityType: EntityType.IRZ,
    apiProps: {
      url: EvidenceAPI.IRZ_FACILITIES,
      getItem: getItemFactory({ combineGetCalls }),
      createItem: createItemFactory({
        postprocessCall: (_, response) => {
          if (!response.ok) {
            if (response.status === 409) {
              throw {
                message: Messages.IrzFacility.CREATE.COORDINATES_NOT_UNIQUE[0],
              };
            }
          }
        },
      }),
      updateItem: updateItemFactory({
        combineGetCalls,
        postprocessCall: (data, response) => {
          if (!response.ok) {
            if (response.status === 409) {
              throw {
                message: Messages.IrzFacility.UPDATE.COORDINATES_NOT_UNIQUE[0],
              };
            }
            if ((data as Exception).code === 'FUTURE_OPERATION_PRESENT') {
              throw {
                message:
                  Messages.IrzFacility.UPDATE.FUTURE_OPERATION_PRESENT[0],
              };
            }
          }
        },
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled provozoven IRZ',
      reportTag: ExportTags.IRZ_FACILITIES,
      include,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.OvzFacility.OVZ_LIST_ALL,
      toolbarProps: {
        after: <IrzFacilitiesTableToolbar />,
      },
    },
    detailProps: {
      FieldsComponent: IrzFacilitiesFields,
      MapComponent: IrzFacilitiesMap,
      validationSchema,
      toolbarProps: {
        title: 'Provozovna IRZ',
        subtitle: useSubTitle<IrzFacility>((data) => data?.name ?? ''),
        ActionBar: IrzFacilitiesActionbar,
        After: IrzFacilitiesToolbar,
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.IrzFacility.IRZ_LIST_ALL,
    },
  });

  return (
    <MapEvidenceContext.Provider value={context}>
      <Evidence2 {...evidence} />
    </MapEvidenceContext.Provider>
  );
}
