/**
 * Browser urls
 */
export enum EvidenceUrl {
  ADMIN_SCHEDULE_JOBS = '/crzp/admin/pravidelne-spustene-ulohy',
  ADMIN_SCHEDULE_RUNS = '/crzp/admin/pravidelne-spustene-ulohy-behy',
  ADMIN_ACTIONS = '/crzp/admin/akce',
  AGENDAS = '/crzp/evidence-agend',
  ALOG = '/crzp/auditni-log',
  BOILER_MANUFACTURERS = '/crzp/registr-ozo/vyrobci',
  BOILER_MANUFACTURER_NEW = '/crzp/registr-ozo/vyrobci/novy',
  BOILER_PERMISSIONS = '/crzp/registr-ozo/opravneni',
  DATABOX_VERIFICATION = '/registrace/odeslani-do-ds',
  BOILER_PERMISSIONS_NEW = '/crzp/registr-ozo/opravneni/nove',
  DICTIONARY_BALANCE_UNITS = '/crzp/mista-uzivani-vody/jednotky-bilance',
  DICTIONARY_BOILER_BRANDS = '/crzp/modul-ciselniku/znacky-kotlu',
  DICTIONARY_BOILER_MODELS = '/crzp/modul-ciselniku/modely-kotlu',
  DICTIONARY_BOILER_TYPES = '/crzp/modul-ciselniku/typy-kotlu',
  DICTIONARY_COMPETENT_AUTHORITIES = '/crzp/modul-ciselniku/kontrolni-organy',
  DICTIONARY_COMPETENT_CONTROL_AUTHORITIES = '/crzp/modul-ciselniku/kompetentni-kontrolni-a-ostatni-organy',
  DICTIONARY_COUNTRIES = '/crzp/modul-ciselniku/staty',
  DICTIONARY_CZ_NACES = '/crzp/modul-ciselniku/cz-nace',
  DICTIONARY_DECISION_TYPES = '/crzp/mista-uzivani-vody/druh-rozhodnuti',
  DICTIONARY_DEPOSITS = '/crzp/chranena-loziskova-uzemi/loziska',
  DICTIONARY_DIRP_AREAS = '/crzp/dirp/oblasti',
  DICTIONARY_DIRP_AUTHORS = '/crzp/dirp/autori',
  DICTIONARY_DIRP_LAWS = '/crzp/dirp/predpisy',
  DICTIONARY_DIRP_LAW_PARAGRAPHS = '/crzp/dirp/paragrafy-predpisu',
  DICTIONARY_DIRP_TYPES = '/crzp/dirp/typy',
  DICTIONARY_EPRTR_ACTIVITIES = '/crzp/modul-ciselniku/cinnosti-e-prtr',
  DICTIONARY_INDICATORS = '/crzp/mista-uzivani-vody/ukazatele-vhr',
  DICTIONARY_INFOS = '/crzp/ciselniky',
  DICTIONARY_IPPC_ACTIVITIES = '/crzp/modul-ciselniku/cinnosti-ippc',
  DICTIONARY_IRZ_ACTIVITIES = '/crzp/modul-ciselniku/cinnosti-irz',
  DICTIONARY_HYDROGEOLOGICAL_ZONES = '/crzp/mista-uzivani-vody/hydrogeologicke-rajony',
  DICTIONARY_LEGAL_FORMS = '/crzp/modul-ciselniku/pravni-formy',
  DICTIONARY_MINERAL_GROUPS = '/crzp/chranena-loziskova-uzemi/skupiny-nerostu',
  DICTIONARY_NACES = '/crzp/modul-ciselniku/nace',
  DICTIONARY_OVSS = '/crzp/chranena-loziskova-uzemi/obor-vykonu-statni-spravy',
  DICTIONARY_PROCESSING_PURPOSE = '/crzp/modul-ciselniku/ucely-zpracovani',
  DICTIONARY_RIVER_BASIN_CZ_DISTRICTS = '/crzp/modul-ciselniku/oblast-povodi-cr',
  DICTIONARY_RIVER_BASIN_EU_DISTRICTS = '/crzp/modul-ciselniku/oblast-povodi-evropy',
  DICTIONARY_RIVER_SIDES = '/crzp/modul-ciselniku/brehy',
  DICTIONARY_SAMPLE_TYPES = '/crzp/mista-uzivani-vody/typy-vzorku',
  DICTIONARY_SEWER_TYPES = '/crzp/modul-ciselniku/typy-kanalizace',
  DICTIONARY_TERRITORIAL_TECHNICAL_UNITS = '/crzp/modul-ciselniku/uzemni-technicka-jednotka',
  DICTIONARY_WATER_FLOWS = '/crzp/modul-ciselniku/vodni-toky',
  DICTIONARY_WATER_ORIGINS = '/crzp/modul-ciselniku/puvody-odebrane-vody',
  DICTIONARY_WATER_TREATMENT_METHODS = '/crzp/modul-ciselniku/zpusoby-upravy-vody',
  DICTIONARY_RUIAN = '/crzp/modul-ciselniku/ruian',
  DICTIONARY_RUIAN_REGION = '/crzp/modul-ciselniku/ruian/region',
  DICTIONARY_RUIAN_CADASTRAL_TERRITORY = '/crzp/modul-ciselniku/ruian/katastralni-uzemi',
  DICTIONARY_RUIAN_DISTRICT = '/crzp/modul-ciselniku/ruian/okres',
  DICTIONARY_RUIAN_ADDRESS_PLACE = '/crzp/modul-ciselniku/ruian/adresni-misto',
  DICTIONARY_RUIAN_MUNICIPALITY = '/crzp/modul-ciselniku/ruian/obec',
  DICTIONARY_RUIAN_STREET = '/crzp/modul-ciselniku/ruian/ulice',
  DICTIONARY_RUIAN_DISTRICT_ADMINISTRATIVE_PRAGUE = '/crzp/modul-ciselniku/ruian/okres-spravni-obvod-praha',
  DICTIONARY_RUIAN_CITY_DISTRICT = '/crzp/modul-ciselniku/ruian/mestska-cast',
  DICTIONARY_RUIAN_CITY_DISTRICT_PRAGUE = '/crzp/modul-ciselniku/ruian/mestsky-obvod-praha',
  DICTIONARY_RUIAN_EXTENDED_COMPETENCE_MUNICIPALITY = '/crzp/modul-ciselniku/ruian/obec-s-rozsirenou-pusobnosti',
  DICTIONARY_RUIAN_COMMISSIONED_LOCAL_AUTHORITY_MUNICIPALITY = '/crzp/modul-ciselniku/ruian/obec-s-poverenym-obecnim-uradem',
  DICTIONARY_RUIAN_COHESION_REGION = '/crzp/modul-ciselniku/ruian/region-soudrznosti',
  DICTIONARY_RUIAN_MUNICIPALITY_PART = '/crzp/modul-ciselniku/ruian/cast-obce',
  DIRP_RECORDS = '/crzp/zaznamy-databaze-informaci-pro-rozhodovaci-praxi',
  EMPOWERMENTS = '/crzp/zmocneni/sprava',
  EMPOWERMENT_REQUESTS = '/crzp/zmocneni/zadosti',
  EMPOWERMENT_REQUESTS_NEW = '/crzp/zmocneni/zadosti/nova',
  EXPORT_ACCESS_RULES = '/crzp/exporty/pravidla-pristupu',
  EXPORT_REQUESTS = '/crzp/admin/exporty',
  EXPORT_TEMPLATES = '/crzp/admin/sablony-exportu',
  IRZ_FACILITIES = '/crzp/provozovny-irz/sprava',
  IRZ_FACILITY_SPLIT = '/crzp/provozovny-irz/rozdeleni',
  IRZ_FACILITY_MERGE = '/crzp/provozovny-irz/slouceni',
  IRZ_FACILITY_REQUESTS = '/crzp/provozovny-irz/zadosti',
  IRZ_FACILITY_REQUEST_CHANGE = '/crzp/provozovny-irz/zadost-o-zmenu-udaju',
  IRZ_FACILITY_REQUEST_NEW = '/crzp/provozovny-irz/registrace',
  IRZ_FACILITY_REQUEST_OPERATOR_CHANGE = '/crzp/provozovny-irz/zadost-o-zmenu-provozovatele',
  ISPOP_DOCUMENTS = '/crzp/logovani-ess',
  MAILING = '/crzp/mailing',
  EMAIL_VERIFICATION = '/overeni-emailu',
  NOTIFICATION_BATCHES = '/crzp/admin/odeslane-notifikace',
  NOTIFICATION_PREFERENCES = '/odhlaseni-notifikace',
  NOTIFICATION_TEMPLATES = '/crzp/admin/sablony-notifikaci',
  OUTAGES = '/crzp/odstavky',
  OUTAGES_PUBLIC = '/crzp/prehled-odstavek',
  OVERVIEW_MAP = '/crzp/prehledova-mapa',
  OVZ_FACILITIES = '/crzp/provozovny-ovz/sprava',
  OVZ_FACILITY_NEW = '/crzp/provozovny-ovz/registrace',
  OVZ_FACILITY_REQUESTS = '/crzp/provozovny-ovz/nabidky',
  OVZ_FACILITY_REQUEST_OPERATOR_CHANGE = '/crzp/provozovny-ovz/nadidka-zmeny-provozovatele',
  OZO_SUBJECTS = '/crzp/registr-ozo/subjekty',
  PERSONAL_DATA = '/crzp/osobni-udaje/sprava',
  PRODUCTION_SITES = '/crzp/prumyslova-mista/sprava',
  PRODUCTION_SITE_MERGE = '/crzp/prumyslova-mista/slouceni',
  PRODUCTION_SITE_SPLIT = '/crzp/prumyslova-mista/rozdeleni',
  PROTECTED_DEPOSIT_AREAS = '/crzp/chranena-loziskova-uzemi',
  PUBLIC_LISTS = '/crzp/verejne-seznamy',
  PUBLIC_LIST_PUBLISHINGS = '/crzp/publikace-verejnych-seznamu',
  PUBLIC_RECORDS = '/crzp/verejne-seznamy/:listId/hodnoty',
  PUBLIC_RECORDS_PUBLIC = '/verejne-seznamy/:listId/hodnoty',
  REPORTING_ACCESS_RULES = '/crzp/reporting/pravidla-pristupu',
  REPORTING_DATA = '/crzp/reporting/data',
  ROLE_INSTANCES = '/crzp/uzivatele/prirazene-role',
  ROLES = '/crzp/uzivatele/role',
  ROLES_REQUESTS = '/crzp/uzivatele/zadosti-o-role',
  ROLE_REQUESTS_NEW = '/crzp/role/zadosti/nova',
  SEQUENCES = '/crzp/ciselne-rady',
  SOAP_EXECUTIONS = '/crzp/logy/soap/volani',
  SOAP_MESSAGE_LOGS = '/crzp/logy/soap/zpravy',
  SOAP_MESSAGES = '/crzp/admin/soap-komunikace',
  SUBJECTS = '/crzp/subjekt/sprava',
  SUBJECT_REQUESTS = '/crzp/subjekt/zadosti',
  SUBJECT_REQUESTS_NEW = '/crzp/subjekt/zadosti/nova',
  SYSTEMS = '/crzp/systemy',
  THIRD_PARTY_SYSTEMS = '/crzp/systemy-tretich-stran',
  UNCONFIRMED_USER_REGISTRATIONS = '/crzp/neaktivovani-uzivatele',
  USERS = '/crzp/uzivatele/sprava',
  TODO = '/crzp/na-tomhle-jeste-pracujeme',
  TRANSLATIONS = '/crzp/textace',
  TRANSLATIONS_PUBLIC = '/textace/odkaz',
  WATER_MANAGEMENT_DECISIONS = '/crzp/mista-uzivani-vody/rozhodnuti/sprava',
  WATER_MANAGEMENT_DECISION_PARTS = '/crzp/mista-uzivani-vody/rozhodnuti/cast/sprava',
  WATER_USAGE_PLACES = '/crzp/mista-uzivani-vody/sprava',
  WEB_SERVICES = '/crzp/webove-sluzby',
}
