import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryWaterFlow } from '../../models';
import { useValidationSchema } from './dict-water-flows-schema';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { DictionaryWaterFlowsFields } from './dict-water-flows-fields';
import { usePermission } from '../../components/permission/perm-evidence-hook';

export function DictionaryWaterFlows() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<DictionaryWaterFlow>({
    options: {
      NEW: [Permission.MuvWaterFlow.MUV_WATER_FLOW_CREATE],
      EDIT: [Permission.MuvWaterFlow.MUV_WATER_FLOW_UPDATE],
      REMOVE: [Permission.MuvWaterFlow.MUV_WATER_FLOW_DELETE],
      CHECK: [
        Permission.MuvWaterFlow.MUV_WATER_FLOW_CREATE,
        Permission.MuvWaterFlow.MUV_WATER_FLOW_UPDATE,
      ],
      SAVE: [
        Permission.MuvWaterFlow.MUV_WATER_FLOW_CREATE,
        Permission.MuvWaterFlow.MUV_WATER_FLOW_UPDATE,
      ],
      CLOSE: [
        Permission.MuvWaterFlow.MUV_WATER_FLOW_CREATE,
        Permission.MuvWaterFlow.MUV_WATER_FLOW_UPDATE,
      ],
    },
  });

  const evidence = useDictionaryEvidence<DictionaryWaterFlow>(
    {
      identifier: PageKey.DICTIONARY_WATER_FLOWS,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_WATER_FLOWS,
      },
      tableProps: {
        tableName: 'Vodní toky',
        reportTag: ExportTags.DICTIONARY_WATER_FLOWS,
        defaultSorts: [],
      },
      detailProps: {
        codePrefix: 'WATER_FLOW',
        validationSchema,
        GeneralFieldsComponent: DictionaryWaterFlowsFields,
        toolbarProps: {
          title: 'Vodní tok',
          showBtn: permissions,
        },
      },
    },
    Permission.MuvWaterFlow.MUV_WATER_FLOW_INVALIDATE,
    Permission.MuvWaterFlow.MUV_WATER_FLOW_REVALIDATE
  );

  return <Evidence2 {...evidence} />;
}
