import { AuthoredObject } from './authored-object';
import { Role, RelationshipRole, SecurityRole, UserRole } from './role';
import { User } from './user';
import { Subject } from './subject';
import { System } from './system';

/**
 * Typ instance role
 */
export enum RoleInstanceType {
  /**
   * Vztahová role
   */
  RELATIONSHIP_ROLE_INSTANCE = 'RELATIONSHIP_ROLE_INSTANCE',

  /**
   * Bezpečnostní role
   */
  SECURITY_ROLE_INSTANCE = 'SECURITY_ROLE_INSTANCE',

  /**
   * Systémová role
   */
  SYSTEM_ROLE_INSTANCE = 'SYSTEM_ROLE_INSTANCE',

  /**
   * Instance uživatelské role
   */
  USER_ROLE_INSTANCE = 'USER_ROLE_INSTANCE',
}

/**
 * Instance role
 */
export interface RoleInstance extends AuthoredObject {
  /**
   * Příznak platnosti
   */
  active?: boolean;

  /**
   * Platnost: od
   */
  validFrom?: string;

  /**
   * Platnost: do
   */
  validTo?: string;

  /**
   * Typ instance role
   */
  type?: RoleInstanceType;

  /**
   * Přiřazená role
   */
  role?: Role;

  /**
   * Detaily SEPNO
   */
  sepnoDetails?: SepnoDetails;
}

/**
 * Instance vztahové role
 */
export interface RelationshipRoleInstance extends RoleInstance {
  /**
   * Přiřazená vztahová role
   */
  role: RelationshipRole;

  /**
   * Typ "vztahová role"
   */
  type: RoleInstanceType.RELATIONSHIP_ROLE_INSTANCE;

  /**
   * Uživatel
   */
  user: User;

  /**
   * Subjekt
   */
  subject: Subject;
}

/**
 * Instance bezpečnostní role
 */
export interface SecurityRoleInstance extends RoleInstance {
  /**
   * Přiřazená bezpečnostní role
   */
  role: SecurityRole;

  /**
   * Typ "vztahová role"
   */
  type: RoleInstanceType.SECURITY_ROLE_INSTANCE;

  /**
   * Uživatel
   */
  user: User;
}

/**
 * Instance systémové role
 */
export interface SystemRoleInstance extends RoleInstance {
  /**
   * Přiřazená systémová role
   */
  role: SecurityRole;

  /**
   * Typ "vztahová role"
   */
  type: RoleInstanceType.SYSTEM_ROLE_INSTANCE;

  /**
   * Systém
   */
  system: System;
}

/**
 * Instance uživatelské role
 */
export interface UserRoleInstance extends RoleInstance {
  /**
   * Přiřazená uživatelská role
   */
  role: UserRole;

  /**
   * Typ "uživatelská role"
   */
  type: RoleInstanceType.USER_ROLE_INSTANCE;

  /**
   * Uživatel
   */
  user: User;

  /**
   * Subjekt
   */
  subject: Subject;
}

/**
 * Detaily pro role AIS SEPNO
 */
export interface SepnoDetails {
  /**
   * Seznam IČP
   */
  icps?: (string | { name: string })[];

  /**
   * Seznam IČZ
   */
  iczs?: (string | { name: string })[];
}
