import React from 'react';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';
import { CohesionRegion } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useColumns } from './dict-ruian-cohesion-region-columns';
import DictionaryRuianCohesionRegionFields from './dict-ruian-cohesion-region-fields';

export function DictionaryRuianCohesionRegion() {
  const evidence = useRuianEvidence<CohesionRegion>({
    identifier: PageKey.DICTIONARY_RUIAN_COHESION_REGION,
    apiProps: {
      url: EvidenceAPI.ADDRESS_COHESION_REGION,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'RUIAN - Regiony soudržnosti',
      reportTag: ExportTags.DICTIONARY_COHESION_REGION,
    },
    detailProps: {
      FieldsComponent: DictionaryRuianCohesionRegionFields,
      toolbarProps: {
        title: 'RUIAN - Region soudržnosti (oblast)',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
