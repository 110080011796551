import React, { useContext, useEffect } from 'react';
import {
  FormTextField,
  FormPanel,
  FormTextArea,
  FormCheckbox,
  FormAutocomplete,
  FormSelect,
  useFormSelector,
  FormInlineTableField,
  DetailContext,
  FormDateTimeField,
  FormContext,
  DetailMode,
} from '@eas/common-web';
import { useSubjectRoles, useRoles } from '../roles/roles-api';
import { useAgendaTypes, useEmpowermentAgendaBehaviors } from './agendas-api';
import { useSpecificationColumns } from './agendas-columns';
import { dictionaryLabelMapper } from '../../components/form/mappers/label-mappers';
import { FormPanelCountByName } from '../../components/form/form-panel-summary/form-panel-count';
import { NotedEvidenceFieldsProps } from '../../components/evidence/noted-evidence/noted-types';
import { HelpLabel } from '../../components/help/help-label';
import {
  Agenda,
  AgendaSpecification,
  AgendaType,
  EmpowermentAgendaBehavior,
} from '../../models';

export function AgendasFields(props: NotedEvidenceFieldsProps) {
  const { isExisting, mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  const subjectRoles = useSubjectRoles();
  const roles = useRoles();
  const agendaTypes = useAgendaTypes();
  const empowermentBehaviors = useEmpowermentAgendaBehaviors();

  const { type, empowerable } = useFormSelector((data: Agenda) => ({
    type: data.type,
    empowerable: data.empowerable,
  }));

  useEffect(() => {
    if ([DetailMode.EDIT, DetailMode.NEW].includes(mode)) {
      if (empowerable) {
        setFieldValue('empowermentBehavior', EmpowermentAgendaBehavior.NOTHING);
      } else {
        setFieldValue('empowermentBehavior', undefined);
      }
    }
  }, [empowerable]);

  const specificationColumns = useSpecificationColumns();

  return (
    <>
      <FormPanel label="Agenda">
        <FormTextField
          name="name"
          label={<HelpLabel label="Název" code="AGENDA_NAME" required />} // Povinné pole
        />
        <FormSelect
          name="type"
          label={<HelpLabel label="Typ agendy" code="AGENDA_TYPE" required />} // Povinné pole
          source={agendaTypes}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled={isExisting}
        />
        <FormTextArea
          name="description"
          label={
            <HelpLabel
              label="Popis podporované agendy dle legislativy"
              code="AGENDA_DESCRIPTION"
            />
          }
        />
        <FormCheckbox
          name="autoAssign"
          label={
            <HelpLabel
              label="Příznak automatického přiřazení agendy k subjektu"
              code="AGENDA_AUTO_ASSIGN"
            />
          }
        />
        <FormCheckbox
          name="selfAssignable"
          label={
            <HelpLabel
              label="Příznak, jestli se subjekt může sám rozhodnout agendu vykonávat"
              code="AGENDA_SELF_ASSIGNABLE"
            />
          }
        />
        <FormCheckbox
          name="empowerable"
          label={
            <HelpLabel
              label="Příznak, jestli může být agenda předmětem zmocnění"
              code="AGENDA_EMPOWERABLE"
            />
          }
        />
        {empowerable && (
          <FormSelect
            name="empowermentBehavior"
            label={
              <HelpLabel
                label="Povinnost přiřazení agendy na subjektech"
                code="AGENDA_EMPOWERMENT_BEHAVIOR"
              />
            }
            source={empowermentBehaviors}
            valueIsId
          />
        )}
        <FormAutocomplete
          name="assignerRoles"
          label={
            <HelpLabel
              label="Role, které mohou agendu přidělit"
              code="AGENDA_ASSIGNER_ROLES"
            />
          }
          source={roles}
          multiple={true}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />
        {type === AgendaType.BASIC && (
          <FormAutocomplete
            name="subjectRoles"
            label={
              <HelpLabel label="Subjektové role" code="AGENDA_SUBJECT_ROLES" />
            } // Subjektové role, které subjekt získá pokud má přidělenou tuto agendu
            source={subjectRoles}
            multiple
            labelMapper={dictionaryLabelMapper}
            tooltipMapper={dictionaryLabelMapper}
          />
        )}
        <FormTextField
          name="rppAttributes.activityRole"
          label={
            <HelpLabel
              label="Činnostní role RPP"
              code="AGENDA_RPP_ATTRIBUTES_ACTIVITY_ROLE"
            />
          }
        />
        <FormTextField
          name="rppAttributes.agenda"
          label={
            <HelpLabel
              label="Agenda dle RPP"
              code="AGENDA_RPP_ATTRIBUTES_AGENDA"
            />
          }
        />
        <FormTextField
          name="rppAttributes.legalReference"
          label={
            <HelpLabel
              label="Zákonná reference"
              code="AGENDA_RPP_ATTRIBUTES_LEGAL_REFERENCE"
            />
          }
        />
        <FormDateTimeField
          name="validFrom"
          label={<HelpLabel label="Platnost od" code="AGENDA_VALID_FROM" />}
        />
        <FormDateTimeField
          name="validTo"
          label={<HelpLabel label="Platnost do" code="AGENDA_VALID_TO" />}
        />
      </FormPanel>
      {type === AgendaType.SPECIFIED && (
        <FormPanel
          label="Specifikace"
          summary={<FormPanelCountByName name="specifications" />}
        >
          <FormInlineTableField<AgendaSpecification>
            name="specifications"
            labelOptions={{ hide: true }}
            layoutOptions={{ noSpacing: true }}
            columns={specificationColumns}
          />
        </FormPanel>
      )}
      {props.HistoryRecords}
    </>
  );
}
