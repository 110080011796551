import React, { useContext } from 'react';
import { Evidence2, DetailContext } from '@eas/common-web';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { getItemFactory } from '../../utils/custom-crud';
import { useColumns } from './role-requests-columns';
import { RoleRequestFields } from './role-requests-fields';
import { RoleRequestsActionbar } from './role-requests-actionbar';
import { combineGetCalls } from './role-requests-api';
import { ExportTags } from '../../enums/export-tags';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { RoleRequest, RoleRequestState } from '../../models';
import { useValidationSchema } from './role-request-schema';

export function RoleRequests() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const permissions = usePermission({
    options: {
      NEW: [],
      EDIT: [
        Permission.RoleRequest.ROLE_REQUEST_UPDATE_ALL,
        Permission.RoleRequest.ROLE_REQUEST_UPDATE_OWN,
      ],
      REMOVE: [],
      CHECK: [
        Permission.RoleRequest.ROLE_REQUEST_UPDATE_ALL,
        Permission.RoleRequest.ROLE_REQUEST_UPDATE_OWN,
      ],
      SAVE: [
        Permission.RoleRequest.ROLE_REQUEST_UPDATE_ALL,
        Permission.RoleRequest.ROLE_REQUEST_UPDATE_OWN,
      ],
      CLOSE: [
        Permission.RoleRequest.ROLE_REQUEST_UPDATE_ALL,
        Permission.RoleRequest.ROLE_REQUEST_UPDATE_OWN,
      ],
    },
  });

  const evidence = useAuthoredEvidence<RoleRequest>({
    identifier: PageKey.ROLE_REQUESTS,
    apiProps: {
      url: EvidenceAPI.ROLE_REQUESTS,
      getItem: getItemFactory({ combineGetCalls }),
    },
    tableProps: {
      columns,
      tableName: 'Přehled žádostí o role',
      reportTag: ExportTags.ROLE_REQUESTS,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: RoleRequestFields,
      validationSchema,
      toolbarProps: {
        title: 'Žádost o roli',
        showBtn: permissions,
        ActionBar: RoleRequestsActionbar,
        disableBtn: function useDisable(btn) {
          const { source } = useContext(DetailContext);

          if (btn === 'EDIT') {
            return source?.data?.state !== RoleRequestState.PENDING;
          }

          return false;
        },
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
