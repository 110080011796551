import React from 'react';
import { MunicipalityPart } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';

export function DictionaryRuianMunicipalityPart() {
  const evidence = useRuianEvidence<MunicipalityPart>(
    {
      identifier: PageKey.DICTIONARY_RUIAN_MUNICIPALITY_PART,
      apiProps: {
        url: EvidenceAPI.ADDRESS_MUNICIPALITY_PART,
      },
      tableProps: {
        tableName: 'RUIAN - Části obce',
        reportTag: ExportTags.DICTIONARY_MUNICIPALITY_PARTS,
      },
      detailProps: {
        toolbarProps: {
          title: 'RUIAN - Část obce',
        },
      },
    },
    {
      hasMunicipality: true,
    }
  );

  return <Evidence2 {...evidence} />;
}
