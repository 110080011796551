import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { IspopDocument } from '../../models';
import { EvidenceAPI, PageKey } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';
import { IspopDocumentsFields } from './ispop-documents-fields';
import { useColumns } from './ispop-documents-columns';

export function IspopDocuments() {
  const permissions = usePermission({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useDatedEvidence<IspopDocument>({
    version: 2,
    identifier: PageKey.ISPOP_DOCUMENTS,
    apiProps: {
      url: EvidenceAPI.ISPOP_DOCUMENTS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled volání přijatých z DS',
      reportTag: ExportTags.ISPOP_DOCUMENTS,
      defaultSorts: [],
      showReportButton: false,
    },
    detailProps: {
      FieldsComponent: IspopDocumentsFields,
      toolbarProps: {
        title: 'Volání přijaté z DS',
        showBtn: permissions,
        ActionBar: DetailEmptyActionbar,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
