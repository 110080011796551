import React, { useRef, useContext } from 'react';
import * as Yup from 'yup';
import { unstable_batchedUpdates } from 'react-dom';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  DetailMode,
  PromptContext,
  usePrompts,
  FormTextArea,
  FormDateField,
} from '@eas/common-web';
import { SubjectRegistration, SubjectRegistrationState } from '../../../models';
import { EvidenceAPI, Permission, Messages } from '../../../enums';
import { useIntl } from 'react-intl';
import { parseISO, isBefore } from 'date-fns';
import { getErrorMessage } from '../../../utils/get-message';

/**
 * Api call
 *
 * @param id
 */
function callApi(
  id: string,
  values: { note?: string; date: string; ceniaNumber?: string }
) {
  return abortableFetch(
    `${EvidenceAPI.SUBJECT_REQUESTS}/${id}/authorize/letter-received/approve`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        authorized: values.date,
        note: values.note,
        ceniaNumber: values.ceniaNumber,
      }),
    }
  );
}

/**
 * Manual confirm subject registration without approving with doc.
 */
const PROMPT_KEY = 'CONFIRM';

export function useConfirmDialog() {
  /**
   * Context stuff.
   */
  const { onPersisted, source, mode } = useContext<
    DetailHandle<SubjectRegistration>
  >(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const intl = useIntl();
  const { testPrompt } = useContext(PromptContext);
  const { reload, hasPermission } = useContext(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: PROMPT_KEY,
        dialogTitle: 'Autorizovat registraci',
        dialogText: 'Skutečně chcete manuálně autorizovat registraci subjektu?',
        FormFields: function Body() {
          return (
            <>
              <FormDateField
                name="date"
                label="Datum"
                helpLabel="Nesmí být starší než datum vytvoření registrace"
                minDatePicker={source.data?.created}
              />
              <FormTextArea name="note" label="Poznámka" />
              <FormTextArea
                name="ceniaNumber"
                label="Spisová služba CENIA (Ev.č./Č.j.)"
              />
            </>
          );
        },
        formValidationSchema: Yup.object().shape({
          date: Yup.date()
            .nullable()
            .required('Povinné pole')
            .test('', '', function (dateValue) {
              const { createError, path } = this;
              if (source.data?.created && dateValue) {
                const minDate = parseISO(source.data?.created);
                minDate.setHours(0, 0, 0, 0);
                if (isBefore(dateValue, minDate)) {
                  return createError({
                    path,
                    message: `Datum musí být po datu vytvoření registrace - ${intl.formatDate(
                      minDate
                    )}`,
                  });
                }
              }
              return true;
            }),
        }),
        dialogWidth: 600,
      },
    ],
    [source.data?.created]
  );

  const handleConfirm = useEventCallback(async () => {
    testPrompt({
      key: PROMPT_KEY,
      submitCallback: async (values: {
        note?: string;
        date: string;
        ceniaNumber?: string;
      }) => {
        try {
          source.setLoading(true);

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi(source.data!.id, values);

          await fetch.current.raw();

          unstable_batchedUpdates(() => {
            showSnackbar(...Messages.SubjectRegistration.CONFIRM.SUCCESS);
            source.setLoading(false);
          });

          reload();
          onPersisted(source.data!.id);
          await source.refresh();
        } catch (err) {
          source.setLoading(false);

          if (err.name !== 'AbortError') {
            const message = getErrorMessage(
              Messages.SubjectRegistration.CONFIRM,
              err.code
            );

            showSnackbar(...message);
            throw err;
          }
          return undefined;
        }
      },
    });
  });

  const showConfirmButton =
    mode === DetailMode.VIEW &&
    source.data?.state === SubjectRegistrationState.WAITING_FOR_AUTHORIZATION &&
    (hasPermission(Permission.Subject.SUBJECT_REQUEST_AUTHORIZE_ALL) ||
      hasPermission(Permission.Subject.SUBJECT_REQUEST_AUTHORIZE_OWN));

  return {
    handleConfirm,
    showConfirmButton,
  };
}
