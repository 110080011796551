import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { CadastralTerritory } from '../../models';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';

export function DictionaryRuianCadastralTerritory() {
  const evidence = useRuianEvidence<CadastralTerritory>(
    {
      identifier: PageKey.DICTIONARY_RUIAN_CADASTRAL_TERRITORY,
      apiProps: {
        url: EvidenceAPI.ADDRESS_CADASTRAL_TERRITORY,
      },
      tableProps: {
        tableName: 'RUIAN - Katastrální území',
        reportTag: ExportTags.DICTIONARY_CADASTRAL_TERRITORY,
      },
      detailProps: {
        toolbarProps: {
          title: 'RUIAN - Katastrální území',
        },
      },
    },
    {
      hasMunicipality: true,
    }
  );

  return <Evidence2 {...evidence} />;
}
