import React from 'react';
import { District } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';

import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useColumns } from './dict-ruian-district-columns';
import DictionaryRuianDistrictFields from './dict-ruian-district-fields';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';

export function DictionaryRuianDistrict() {
  const evidence = useRuianEvidence<District>({
    identifier: PageKey.DICTIONARY_RUIAN_DISTRICT,
    apiProps: {
      url: EvidenceAPI.ADDRESS_DISCTRICT,
    },
    tableProps: {
      // memoized array must *not* be passed to the tableProps,
      // because `useDatedEvidence` pushes some columns into it
      // and it doesn't check, if it pushes the same columns again
      columns: useColumns(),
      tableName: 'RUIAN - Okresy',
      reportTag: ExportTags.DICTIONARY_DISTRICT,
    },
    detailProps: {
      FieldsComponent: DictionaryRuianDistrictFields,
      toolbarProps: {
        title: 'RUIAN - Okres',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
