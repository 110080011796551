import React, { useContext } from 'react';
import {
  Evidence2,
  DetailContext,
  UserContext,
  DetailHandle,
} from '@eas/common-web';
import {
  useColumns,
  empowermentRequestColumnFactory,
} from './empowerment-requests-columns';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { EmpowermentRequestsFields } from './empowerment-requests-fields';
import { EmpowermentRequestToolbar } from './empowerment-requests-toolbar';
import { ExportTags } from '../../enums/export-tags';
import { EmpowermentRequest } from '../../models';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { EmpowermentRequestActionbar } from './empowerment-requests-actionbar';
import { useSpecialFilters } from './empowerment-requests-special-filters';
import { useHistoryDescriptors } from './empowerment-requests-history';

function EmptyWrapper({ children }: { children?: React.ReactNode }) {
  return <>{children}</>;
}

export function EmpowermentRequests() {
  const permissions = usePermission<EmpowermentRequest>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useDatedEvidence<EmpowermentRequest>({
    identifier: PageKey.EMPOWERMENT_REQUESTS,
    apiProps: {
      url: EvidenceAPI.EMPOWERMENT_REQUESTS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled žádostí o zmocnění',
      reportTag: ExportTags.EMPOWERMENT_REQUESTS,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.Empowerment.EMPOWERMENT_REQUEST_LIST_ALL,
      columnWrapper: empowermentRequestColumnFactory,
    },
    detailProps: {
      FieldsComponent: EmpowermentRequestsFields,
      ContainerComponent: EmptyWrapper,
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Žádost o zmocnění',
        After: EmpowermentRequestToolbar,
        ActionBar: EmpowermentRequestActionbar,
        showBtn: function useShow(btn) {
          const { user } = useContext(UserContext);
          const { source } = useContext<DetailHandle<EmpowermentRequest>>(
            DetailContext
          );

          if (
            source?.data?.createdBy?.id === user.id &&
            ['EDIT', 'SAVE', 'CLOSE'].includes(btn)
          ) {
            return true;
          }

          return permissions(btn);
        },
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Empowerment.EMPOWERMENT_REQUEST_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}
