import { useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import * as Yup from 'yup';
import {
  DetailContext,
  SnackbarContext,
  AbortableFetch,
  UserContext,
  useEventCallback,
  DetailHandle,
  abortableFetch,
  PromptContext,
  usePrompts,
} from '@eas/common-web';
import { SubjectUserDto, SubjectRelationship } from '../../subjects-types';
import { getErrorMessage } from '../../../../utils/get-message';
import { AssignUserDialog } from './assign-user-dialog';
import {
  Subject,
  RelationshipType,
  Role,
  RoleType,
  Me,
  SepnoDetails,
  RoleInstance,
} from '../../../../models';
import { Messages, EvidenceAPI, Permission } from '../../../../enums';
import {
  iczFormat,
  icpFormat,
} from '../../../role-instance/role-instances-schema';

interface AssignUserFormData {
  username: string;
  relationship: RelationshipType;
  userRoles?: Role[];
  userRoleSepnoDetails?: { role: Role; icps: string[]; iczs: string[] }[];
}

/**
 * Api call
 *
 * @param username
 * @param subject
 * @param relationship
 * @param userRoles
 */
function callApi(
  username: string,
  subject: Subject,
  relationship: RelationshipType,
  userRoles?: Role[],
  userRoleSepnoDetails?: { role: Role; icps: string[]; iczs: string[] }[]
) {
  return abortableFetch(
    `${EvidenceAPI.USERS}/assign-to/${subject.id}?username=${encodeURIComponent(
      username
    )}`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        relationship,
        userRoles,
        userRoleSepnoDetails,
      }),
    }
  );
}

function getActiveUserRoles(relationshipRoleInstances: SubjectRelationship[]) {
  const userRoles: Role[] = [];
  const userRoleInstances: RoleInstance[] = [];

  relationshipRoleInstances.forEach((relationship) => {
    relationship.userRoleInstances.forEach((userRole) => {
      const isDuplicate = !!userRoles.find(
        (role) => role.id === userRole.role.id
      );

      if (isDuplicate) return;

      if (userRole.active) {
        userRoles.push({ ...userRole.role, type: RoleType.USER_ROLE });
        userRoleInstances.push(userRole);
      }
    });
  });

  return { userRoles, userRoleInstances };
}

/**
 * Přiřazení uživatele k subjektu
 */
const PROMPT_KEY = 'ASSIGN_USER';

export function useAssignUserDialog({
  users,
  selectedIndex,
  inDetailToolbar = false,
}: {
  users: SubjectUserDto[];
  selectedIndex: number | undefined;
  inDetailToolbar?: boolean;
}) {
  /**
   * Context stuff.
   */
  const { onPersisted, source, isExisting } = useContext<DetailHandle<Subject>>(
    DetailContext
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { testPrompt } = useContext(PromptContext);
  const { hasPermission, reload } = useContext<UserContext<Me>>(UserContext);

  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const formInitialValues =
    selectedIndex !== undefined
      ? {
          username: users?.[selectedIndex]?.user?.username,
          relationship: users?.[selectedIndex]?.relationship,
          userRoles: getActiveUserRoles(
            users?.[selectedIndex]?.relationshipRoleInstances ?? []
          )?.userRoles,
          userRoleSepnoDetails: getActiveUserRoles(
            users?.[selectedIndex]?.relationshipRoleInstances ?? []
          )
            ?.userRoleInstances.filter((i) => i.role?.allowSepnoDetails)
            .map((i) => ({
              role: i.role,
              ...i.sepnoDetails,
            })),
        }
      : {};

  const formValidationSchema = Yup.object<AssignUserFormData>().shape({
    username: Yup.string().nullable().required('Uživatel je povinný'),
    relationship: Yup.mixed<RelationshipType>()
      .nullable()
      .required('Vztah je povinný'),
    userRoles: Yup.array<Role>().nullable(),
    userRoleSepnoDetails: Yup.array().test('', '', function (value) {
      const { createError, parent } = this;
      const sepnoDetails = value as SepnoDetails[];

      if (
        (parent.userRoles ?? []).some((r: Role) => r.allowSepnoDetails) &&
        sepnoDetails?.some(
          (d) => (d.icps ?? []).length === 0 && (d.iczs ?? []).length === 0
        )
      ) {
        return createError({
          path: 'userRoleSepnoDetails',
          message:
            'Musí být vyplněno alespoň jedno IČZ nebo IČP pro každou roli',
        });
      }

      for (const detail of sepnoDetails ?? []) {
        const icps = detail?.icps ?? [];
        const iczs = detail?.iczs ?? [];

        for (const icp of icps) {
          if (!(icp as string).match(icpFormat)) {
            return createError({
              path: 'userRoleSepnoDetails',
              message:
                'IČP může mít maximálně 12 alfanumerických znaků (nesmí nabývat hodnoty 0), nesmí začínat ani končit mezerou nebo řadou mezer, nesmí začínat písmeny CZ, CS ani CO',
            });
          }
        }

        for (const icz of iczs) {
          if (!(icz as string).match(iczFormat)) {
            return createError({
              path: 'userRoleSepnoDetails',
              message:
                'IČZ musí obsahovat právě 8 znaků, formát CZXYYYYY nebo CSXYYYYY; kde X (x=1) je písmeno a smí nabývat pouze hodnot "A; S; U; L; K; H; E; P; C; J; B; M; T; Z" a YYYYY je pořadové číslo evidovaného zařízení smí nabývat hodnot 0 - 9 (y=5)',
            });
          }
        }
      }

      return true;
    }),
  }) as Yup.Schema<AssignUserFormData>;

  /**
   * Dialog.
   */
  usePrompts(
    [
      {
        key: `${PROMPT_KEY}${inDetailToolbar ? '_IN_TOOLBAR' : ''}`,
        dialogTitle: `${
          selectedIndex !== undefined ? 'Editace' : 'Vytvoření'
        } vazby uživatele na subjekt`,
        dialogText: 'Vyplňte údaje:',
        formInitialValues,
        FormFields: AssignUserDialog,
        formValidationSchema,
        dialogWidth: 1000,
      },
    ],
    [source.data, selectedIndex, users]
  );

  const submitCallback = async (values: AssignUserFormData) => {
    try {
      source.setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(
        values.username,
        source.data!,
        values.relationship,
        values.userRoles,
        values.userRoleSepnoDetails
      );

      await fetch.current.raw();

      unstable_batchedUpdates(() => {
        showSnackbar(
          ...(users.find(
            (assignment) => assignment.user?.username === values?.username
          )
            ? Messages.Subject.USER_ASSIGN.RELATIONSHIP_CHANGED
            : Messages.Subject.USER_ASSIGN.SUCCESS)
        );
        source.setLoading(false);
      });

      reload();
      onPersisted(source.data!.id);
      await source.refresh();
    } catch (err) {
      source.setLoading(false);

      if (err.name !== 'AbortError') {
        const message = getErrorMessage(Messages.Subject.USER_ASSIGN, err.code);

        showSnackbar(...message);
      }

      return true;
    }
  };

  const handleAssignUser = useEventCallback(async () => {
    testPrompt({
      key: `${PROMPT_KEY}${inDetailToolbar ? '_IN_TOOLBAR' : ''}`,
      submitCallback,
    });
  });

  const canAssign =
    isExisting &&
    source?.data?.active &&
    [
      Permission.User.USER_ASSIGN_ALL,
      Permission.User.USER_ASSIGN_OWN,
    ].some((perm) => hasPermission(perm, { subjectId: source.data?.id }));

  return {
    handleAssignUser,
    canAssign,
    formInitialValues: formInitialValues as AssignUserFormData,
    submitCallback,
    formValidationSchema,
  };
}
