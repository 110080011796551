import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { OvzFacilityRequest } from '../../models';
import { OvzFacilityRequestsFields } from './ovz-facility-requests-fields';
import {
  useColumns,
  ovzFacilityRequestColumnFactory,
} from './ovz-facility-requests-columns';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { EntityType } from '../../enums/entity-type';
import { OvzFacilityRequestsActionbar } from './ovz-facility-requests-actionbar';
import { OvzFacilityRequestsToolbar } from './ovz-facility-requests-toolbar';
import { useSpecialFilters } from './ovz-facility-requests-special-filters';
import { combineGetCalls } from './ovz-facility-requests-api';
import { getItemFactory } from '../../utils/custom-crud';
import { useHistoryDescriptors } from './ovz-facility-requests-history';

export function OvzFacilityRequests() {
  const permissions = usePermission<OvzFacilityRequest>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<OvzFacilityRequest>({
    identifier: PageKey.OVZ_FACILITY_REQUESTS,
    noteEntityType: EntityType.OVZ_FACILITY_REQUEST,
    apiProps: {
      url: EvidenceAPI.OVZ_FACILITY_REQUESTS,
      getItem: getItemFactory({ combineGetCalls }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled nabídek provozoven OVZ',
      reportTag: ExportTags.OVZ_FACILITY_REQUESTS,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.OvzFacility.OVZ_REQUEST_LIST_ALL,
      columnWrapper: ovzFacilityRequestColumnFactory,
    },
    detailProps: {
      FieldsComponent: OvzFacilityRequestsFields,
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Nabídka provozovny OVZ',
        After: OvzFacilityRequestsToolbar,
        ActionBar: OvzFacilityRequestsActionbar,
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.OvzFacility.OVZ_REQUEST_CHANGE_OPERATOR_FINISH_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}
