import React from 'react';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { DictionaryDirpLawsFields } from './dict-dirp-laws-fields';
import { useValidationSchema } from './dict-dirp-laws-schema';
import { useColumns } from './dict-dirp-laws-columns';
import { DictionaryLaw } from '../../models';
import { Permission, PageKey, EvidenceAPI, Messages } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { createItemFactory, updateItemFactory } from '../../utils/custom-crud';
import { getErrorMessage } from '../../utils/get-message';

export function DictionaryDirpLaws() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission({
    options: {
      NEW: [Permission.Dirp.DIRP_LAW_UPDATE],
      EDIT: [Permission.Dirp.DIRP_LAW_UPDATE],
      REMOVE: [],
      CHECK: [Permission.Dirp.DIRP_LAW_UPDATE],
      SAVE: [Permission.Dirp.DIRP_LAW_UPDATE],
      CLOSE: [Permission.Dirp.DIRP_LAW_UPDATE],
    },
  });

  const evidence = useDictionaryEvidence<DictionaryLaw>(
    {
      identifier: PageKey.DICTIONARY_DIRP_LAWS,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_DIRP_LAWS,
        createItem: createItemFactory<DictionaryLaw>({
          postprocessCall: (data, response) => {
            if (!response?.ok) {
              const message = getErrorMessage(
                Messages.Dirp.LAW_CREATE,
                data.code
              )[0];

              throw { message };
            }
          },
        }),
        updateItem: updateItemFactory<DictionaryLaw>({
          postprocessCall: (data, response) => {
            if (!response?.ok) {
              const message = getErrorMessage(
                Messages.Dirp.LAW_UPDATE,
                data.code
              )[0];

              throw { message };
            }
          },
        }),
      },
      tableProps: {
        tableName: 'Předpisy',
        reportTag: ExportTags.DICTIONARY_DIRP_LAWS,
        columns: useColumns(),
        showColumn: (col) =>
          !['ORDER', 'NAME', 'CODE', 'VALID_FROM', 'VALID_TO'].includes(col),
        defaultSorts: [],
      },
      detailProps: {
        codePrefix: 'DIRP_LAW',
        validationSchema,
        hideGeneralFields: true,
        FieldsComponent: DictionaryDirpLawsFields,
        toolbarProps: {
          title: 'Předpis',
          showBtn: permissions,
        },
      },
    },
    Permission.Dirp.DIRP_INVALIDATE,
    Permission.Dirp.DIRP_LAW_REVALIDATE
  );

  return <Evidence {...evidence} />;
}
