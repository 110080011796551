import React, { useContext, useMemo } from 'react';
import { Evidence2, UserContext } from '@eas/common-web';
import { v4 as uuidv4 } from 'uuid';
import { Subject, Exception, Me, PermissionsState } from '../../models';
import { SubjectsFields } from './subjects-fields';
import { useColumns } from './subjects-columns';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  Messages,
  IncludeOptions,
} from '../../enums';
import { SubjectsToolbar } from './subjects-toolbar';
import { ExportTags } from '../../enums/export-tags';
import {
  getItemFactory,
  updateItemFactory,
  createItemFactory,
} from '../../utils/custom-crud';
import { combineGetCalls } from './subjects-api';
import { useValidationSchema } from './subjects-schema';
import { formatIcoOnSubject } from './fields/subjects-utils';
import { EntityType } from '../../enums/entity-type';
import { SubjectsActionbar } from './subjects-actionbar';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
import { useHistoryDescriptors } from './subjects-history';
import { useSpecialFilters } from './overrides/subjects-special-filters';
import { SubjectsTableToolbar } from './subjects-table-toolbar';
import { preprocessPhoneNumber } from '../../components/form/phone-field/phone-field-utils';
import { getErrorMessage } from '../../utils/get-message';
import { useSubjectHookedSources } from './subjects-hooked-sources';

export function Subjects() {
  const validationSchema = useValidationSchema();

  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const hasUpdateAll = hasPermission(Permission.Subject.SUBJECT_UPDATE_ALL, {
    searchInSubjectPerms: true,
  });

  const hasUpdateOwn = (data: Subject) => {
    if (hasUpdateAll) {
      return true;
    } else if (data?.id) {
      return hasPermission(Permission.Subject.SUBJECT_UPDATE_OWN, {
        searchOnlyInSubjectPerms: true,
        subjectId: data.id,
      });
    } else {
      return false;
    }
  };

  const permissions = usePermission<Subject>({
    options: {
      NEW: [Permission.Subject.SUBJECT_CREATE],
      EDIT: [
        Permission.Subject.SUBJECT_UPDATE_ALL,
        Permission.Subject.SUBJECT_UPDATE_OWN,
      ],
      REMOVE: [],
      CHECK: [
        Permission.Subject.SUBJECT_CREATE,
        Permission.Subject.SUBJECT_UPDATE_ALL,
        Permission.Subject.SUBJECT_UPDATE_OWN,
      ],
      SAVE: [
        Permission.Subject.SUBJECT_CREATE,
        Permission.Subject.SUBJECT_UPDATE_ALL,
        Permission.Subject.SUBJECT_UPDATE_OWN,
      ],
      CLOSE: [
        Permission.Subject.SUBJECT_CREATE,
        Permission.Subject.SUBJECT_UPDATE_ALL,
        Permission.Subject.SUBJECT_UPDATE_OWN,
      ],
    },
    dataCheck: {
      EDIT: hasUpdateOwn,
      SAVE: hasUpdateOwn,
      CLOSE: hasUpdateOwn,
    },
  });

  const canSeeDeactivated = useMemo(
    () => hasPermission(Permission.Subject.SUBJECT_INVALIDATE),
    [hasPermission]
  );

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  const historyDescriptors = useHistoryDescriptors();

  const validateAddress = (item: Subject) => {
    if (item?.residence?.country?.ruian) {
      const {
        region,
        district,
        municipality,
        cityDistrict,
        municipalityPart,
        street,
        // addressPlace,
        ...restAddressFields
      } = item.residence;

      item.residence = {
        ...restAddressFields,
      };
    }
  };

  const evidence = useDatedEvidence<Subject>({
    version: 3,
    noteEntityType: EntityType.SUBJECT,
    identifier: PageKey.SUBJECTS,
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Subject.SUBJECT_LIST_ALL,
    },
    apiProps: {
      url: EvidenceAPI.SUBJECTS,
      hookedSources: useSubjectHookedSources,
      getItem: getItemFactory({
        combineGetCalls,
        postprocessCall: async (item) => validateAddress(item),
      }),
      updateItem: updateItemFactory({
        combineGetCalls,
        preprocessCall: async (item) => {
          return preprocessPhoneNumber(formatIcoOnSubject(item));
        },
      }),
      createItem: createItemFactory({
        combineGetCalls,
        preprocessCall: async (item) => {
          return preprocessPhoneNumber(formatIcoOnSubject(item));
        },
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.EmpowermentRequest.APPROVE,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
    },
    tableProps: {
      columns: useColumns(canSeeDeactivated),
      tableName: 'Přehled subjektů',
      reportTag: ExportTags.SUBJECTS,
      include,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.Subject.SUBJECT_LIST_ALL,
      toolbarProps: {
        after: <SubjectsTableToolbar />,
      },
    },
    detailProps: {
      FieldsComponent: SubjectsFields,
      hideGeneralFields: true,
      validationSchema,
      initNewItem: () => ({
        id: uuidv4(),
        phoneNumber: {
          prefix: '+420',
        },
      }),
      toolbarProps: {
        title: 'Subjekt',
        subtitle: useSubTitle<Subject>((data) => data?.label ?? ''),
        After: SubjectsToolbar,
        ActionBar: SubjectsActionbar,
        showBtn: permissions,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
