import React from 'react';
import { DetailToolbarProps } from '@eas/common-web';
import { DetailToolbar } from '../../components/evidence/evidence/detail-toolbar';
import { useSaveDialog } from './dialog-actions/save-hook';
import { User } from '../../models';

export function UsersToolbar(props: DetailToolbarProps<User>) {
  const { handleSave } = useSaveDialog();

  return <DetailToolbar {...props} handleSave={handleSave} />;
}
