import React from 'react';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { useValidationSchema } from './dict-ovss-schema';
import { DictionaryOvss as DictOvss } from '../../models';
import { EvidenceAPI, PageKey } from '../../enums';
import { ExportTags } from '../../enums/export-tags';

export function DictionaryOvss() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictOvss>({
    identifier: PageKey.DICTIONARY_OVSS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_OVSS,
    },
    tableProps: {
      tableName: 'Obory výkonu státní správy',
      reportTag: ExportTags.DICTIONARY_OVSS,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'OVSS',
      validationSchema,
      toolbarProps: {
        title: 'Odbor výkonu státní správy',
      },
    },
  });

  return <Evidence {...evidence} />;
}
