import {
  TableColumn,
  TableCells,
  TableFilterCells,
  ApiFilterOperation,
} from '@eas/common-web';
import { SoapMessageLog } from '../../models';
import { useWebServices } from '../web-service/web-services-api';

export function useColumns(): TableColumn<SoapMessageLog>[] {
  return [
    {
      datakey: 'localPart',
      name: 'Operace',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'service',
      sortkey: 'service.name',
      filterkey: 'service.id',
      name: 'Název služby',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useWebServices),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useWebServices
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'version',
      name: 'Verze služby (rozhraní)',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'createdBy',
      displaykey: 'createdBy.name',
      filterkey: 'createdBy.name',
      sortkey: 'createdBy.name',
      name: 'Autor volání',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'duration',
      name: 'Trvání operace (ms)',
      width: 200,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'created',
      name: 'Vytvoření',
      width: 150,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      sortable: true,
      filterable: true,
    },
  ];
}
