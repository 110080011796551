import React from 'react';
import { Evidence2, EmptyComponent } from '@eas/common-web';
import { DictionaryCompetentAuthority } from '../../models';
import { DictionaryCompetentAuthoritiesFields } from './dict-competent-authorities-fields';
import { EvidenceAPI, PageKey } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';

export function DictionaryCompetentAuthorities() {
  const evidence = useDictionaryEvidence<DictionaryCompetentAuthority>({
    identifier: PageKey.DICTIONARY_COMPETENT_AUTHORITIES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_COMPETENT_AUTHORITIES,
    },
    tableProps: {
      tableName: 'Kompetení orgány IRZ',
      reportTag: ExportTags.DICTIONARY_COMPETENT_AUTHORITIES,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'COMPETENT_AUTHORITY',
      GeneralFieldsComponent: DictionaryCompetentAuthoritiesFields,
      ToolbarComponent: EmptyComponent,
    },
  });

  return <Evidence2 {...evidence} />;
}
