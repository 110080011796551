import React, { useContext, useMemo } from 'react';
import { Evidence2, UserContext } from '@eas/common-web';
import { OvzFacility, Me, PermissionsState, Exception } from '../../models';
import { OvzFacilitiesFields } from './ovz-facilities-fields';
import { useColumns } from './ovz-facilities-columns';
import { useValidationSchema } from './ovz-facilities-schema';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  IncludeOptions,
  Messages,
} from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { OvzFacilitiesMap } from './ovz-facilities-map';
import { useMapEvidence } from '../../components/evidence/map-evidence/map-evidence';
import { ExportTags } from '../../enums/export-tags';
import { EntityType } from '../../enums/entity-type';
import { OvzFacilitiesActionbar } from './ovz-facilities-actionbar';
import { OvzFacilitiesToolbar } from './ovz-facilities-toolbar';
import { MapEvidenceContext } from '../../components/evidence/map-evidence/map-context';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useSpecialFilters } from './ovz-facilities-special-filters';
import { OvzFacilitiesTableToolbar } from './ovz-facilities-table-toolbar';
import { getItemFactory, updateItemFactory } from '../../utils/custom-crud';
import { combineGetCalls } from './ovz-facilities-api';
import { useHistoryDescriptors } from './ovz-facilities-history';

/**
 * TODO: http://git.inqool.cz/eas/projects/crzp/-/issues/507 - poslední bod BE
 * Doplnit do gridu a detailu informaci o související nabídce
 * V případě, že je nabídka k dispozici není možné provozovnu ovz editovat, zrušit...
 */

export function OvzFacilities() {
  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );
  const validationSchema = useValidationSchema();

  const permissions = usePermission<OvzFacility>({
    options: {
      NEW: [Permission.OvzFacility.OVZ_CREATE_ALL],
      EDIT: [
        Permission.OvzFacility.OVZ_UPDATE_ALL,
        Permission.OvzFacility.OVZ_UPDATE_OWN,
      ],
      REMOVE: [],
      CHECK: [
        Permission.OvzFacility.OVZ_CREATE_ALL,
        Permission.OvzFacility.OVZ_UPDATE_ALL,
        Permission.OvzFacility.OVZ_UPDATE_OWN,
      ],
      SAVE: [
        Permission.OvzFacility.OVZ_CREATE_ALL,
        Permission.OvzFacility.OVZ_UPDATE_ALL,
        Permission.OvzFacility.OVZ_UPDATE_OWN,
      ],
      CLOSE: [
        Permission.OvzFacility.OVZ_CREATE_ALL,
        Permission.OvzFacility.OVZ_UPDATE_ALL,
        Permission.OvzFacility.OVZ_UPDATE_OWN,
      ],
    },
    dataCheck: {
      EDIT: (data) =>
        hasPermission(Permission.OvzFacility.OVZ_UPDATE_ALL) ||
        hasPermission(Permission.OvzFacility.OVZ_UPDATE_OWN, {
          subjectId: data?.operator?.id,
        }),
    },
  });

  const canSeeDeactivated = useMemo(
    () =>
      [
        Permission.OvzFacility.OVZ_REVALIDATE_ALL,
        Permission.OvzFacility.OVZ_REVALIDATE_OWN,
      ].some((perm) => hasPermission(perm, { searchInSubjectPerms: true })),
    [hasPermission]
  );

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  const historyDescriptors = useHistoryDescriptors();

  const { evidence, context } = useMapEvidence<OvzFacility>({
    version: 9,
    identifier: PageKey.OVZ_FACILITIES,
    noteEntityType: EntityType.OVZ,
    apiProps: {
      url: EvidenceAPI.OVZ_FACILITIES,
      getItem: getItemFactory({ combineGetCalls }),
      updateItem: updateItemFactory({
        combineGetCalls,
        postprocessCall: (data, response) => {
          if (!response.ok) {
            if ((data as Exception).code === 'FUTURE_OPERATION_PRESENT') {
              throw {
                message:
                  Messages.OvzFacility.UPDATE.FUTURE_OPERATION_PRESENT[0],
              };
            } else if ((data as Exception).code === 'NAME_NOT_UNIQUE') {
              throw { message: Messages.OvzFacility.UPDATE.NAME_NOT_UNIQUE[0] };
            } else if ((data as Exception).code === 'IRZ_FACILITY_NOT_VALID') {
              throw {
                message: Messages.OvzFacility.UPDATE.IRZ_FACILITY_NOT_VALID[0],
              };
            } else {
              throw { message: Messages.OvzFacility.UPDATE.ERROR[0] };
            }
          }
        },
      }),
    },
    tableProps: {
      columns: useColumns(),
      showColumn: (column) => {
        if (column === 'CREATED') {
          return false;
        }
        return true;
      },
      tableName: 'Přehled provozoven OVZ',
      reportTag: ExportTags.OVZ_FACILITIES,
      defaultSorts: [],
      include,
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.OvzFacility.OVZ_LIST_ALL,
      toolbarProps: {
        after: <OvzFacilitiesTableToolbar />,
      },
    },
    detailProps: {
      FieldsComponent: OvzFacilitiesFields,
      MapComponent: OvzFacilitiesMap,
      // hideGeneralFields: true,
      toolbarProps: {
        title: 'Provozovna OVZ',
        subtitle: useSubTitle<OvzFacility>((data) => data?.name ?? ''),
        After: OvzFacilitiesToolbar,
        ActionBar: OvzFacilitiesActionbar,
        showBtn: permissions,
      },
      validationSchema,
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.OvzFacility.OVZ_LIST_ALL,
    },
  });

  return (
    <MapEvidenceContext.Provider value={context}>
      <Evidence2 {...evidence} />
    </MapEvidenceContext.Provider>
  );
}
