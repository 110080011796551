import React from 'react';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { useValidationSchema } from './dict-mineral-groups-schema';
import { EvidenceAPI, PageKey } from '../../enums';
import { DictionaryMineralGroup } from '../../models';
import { ExportTags } from '../../enums/export-tags';

export function DictionaryMineralGroups() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryMineralGroup>({
    identifier: PageKey.DICTIONARY_MINERAL_GROUPS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_MINERAL_GROUP,
    },
    tableProps: {
      tableName: 'Skupiny nerostů',
      reportTag: ExportTags.DICTIONARY_MINERAL_GROUPS,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'MINERAL_GROUPS',
      validationSchema,
      toolbarProps: {
        title: 'Skupina nerostů',
      },
    },
  });

  return <Evidence {...evidence} />;
}
