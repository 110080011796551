import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { useColumns } from './notification-batches-columns';
import { ExportTags } from '../../enums/export-tags';
import { NotificationTemplate } from '../../models';
import { EvidenceAPI, PageKey } from '../../enums';
import { useDatedEvidence } from '../../components/evidence/dated-evidence/dated-evidence';
// import { NotificationTemplatesActionbar } from './notification-templates-actionbar';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { NotificationBatchesFields } from './notification-batches-fields';
import { DetailEmptyActionbar } from '../../components/evidence/evidence/detail-empty-actionbar';

export function NotificationBatches() {
  const columns = useColumns();

  const permissions = usePermission({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [],
      SAVE: [],
      CLOSE: [],
    },
  });

  const evidence = useDatedEvidence<NotificationTemplate>({
    identifier: PageKey.NOTIFICATION_BATCHES,
    apiProps: {
      url: EvidenceAPI.NOTIFICATION_BATCHES,
    },
    tableProps: {
      columns,
      columnLabelMapper: (column) => {
        if (column === 'CREATED') {
          return 'Datum odeslání';
        }
        return undefined;
      },
      showColumn: (column) => {
        if (column === 'UPDATED') {
          return false;
        }
        return true;
      },
      tableName: 'Přehled notifikačních dávek',
      reportTag: ExportTags.NOTIFICATION_BATCHES,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: NotificationBatchesFields,
      toolbarProps: {
        title: 'Notifikačná dávka',
        ActionBar: DetailEmptyActionbar,
        showBtn: permissions,
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
