import React from 'react';
import {
  FormInlineTableField,
  FormPanel,
  InlineTableFieldCells,
  FormCustomField,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { HelpLabel } from '../../../components/help/help-label';

export const useStyles = makeStyles((theme) => ({
  warning: {
    lineHeight: 'inherit',
    color: theme.palette.error.main,
    fontSize: 12,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export function SepnoDetails() {
  const icpCell = InlineTableFieldCells.useInlineTextFieldFactory({
    collectionDatakey: 'sepnoDetails.icps',
  });

  const iczCell = InlineTableFieldCells.useInlineTextFieldFactory({
    collectionDatakey: 'sepnoDetails.iczs',
  });

  return (
    <FormPanel
      label={
        <HelpLabel label="SEPNO detaily" code="ROLE_INSTANCES_SEPNO_DETAILS" />
      }
    >
      <FormInlineTableField
        name="sepnoDetails.icps"
        label="IČP"
        columns={[
          {
            datakey: 'name',
            name: 'IČP',
            width: 300,
            CellComponent: icpCell,
          },
        ]}
      />
      <FormInlineTableField
        name="sepnoDetails.iczs"
        label="IČZ"
        columns={[
          { datakey: 'name', name: 'IČZ', width: 300, CellComponent: iczCell },
        ]}
      />
    </FormPanel>
  );
}

export function DialogSepnoDetails() {
  const classes = useStyles();

  const icpCell = InlineTableFieldCells.useInlineTextFieldMultipleFactory({
    collectionDatakey: 'userRoleSepnoDetails',
  });

  const iczCell = InlineTableFieldCells.useInlineTextFieldMultipleFactory({
    collectionDatakey: 'userRoleSepnoDetails',
  });

  return (
    <FormCustomField label="SEPNO detaily">
      <div className={classes.wrapper}>
        <FormInlineTableField
          name="userRoleSepnoDetails"
          labelOptions={{ hide: true }}
          layoutOptions={{ noUnderline: true }}
          showToolbar={false}
          withRemove={false}
          columns={[
            {
              datakey: 'role.name',
              name: 'Role',
              width: 200,
            },
            {
              name: 'IČP',
              datakey: 'icps',
              width: 200,
              CellComponent: icpCell,
            },
            {
              name: 'IČZ',
              datakey: 'iczs',
              width: 200,
              CellComponent: iczCell,
            },
          ]}
        />
        <Typography variant="body1" className={classes.warning}>
          Je možné zadat více hodnot, každou hodnotu je nutné potvrdit
          stisknutím klávesy Enter.
        </Typography>
      </div>
    </FormCustomField>
  );
}
