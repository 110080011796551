import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryBoilerType } from '../../models';
import { DictionaryBoilerTypesFields } from './dict-boiler-types-fields';
import { useValidationSchema } from './dict-boiler-types-schema';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { useHistoryDescriptors } from './dict-boiler-types-history';

export function DictionaryBoilerTypes() {
  const validationSchema = useValidationSchema();

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useDictionaryEvidence<DictionaryBoilerType>({
    identifier: PageKey.DICTIONARY_BOILER_TYPES,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_BOILER_TYPES,
    },
    tableProps: {
      tableName: 'Typy kotlů',
      reportTag: ExportTags.DICTIONARY_BOILER_TYPES,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'BOILER_TYPE',
      FieldsComponent: DictionaryBoilerTypesFields,
      hideGeneralFields: true,
      validationSchema,
      toolbarProps: {
        title: 'Typ kotlů',
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Dictionary.DICTIONARY_LIST,
    },
  });

  return <Evidence2 {...evidence} />;
}
