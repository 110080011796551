import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Evidence2,
  DetailContext,
  DetailHandle,
  getItemFactory,
  updateItemFactory,
} from '@eas/common-web';
import { createItemFactory } from '../../utils/custom-crud';
import { getErrorMessage } from '../../utils/get-message';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useHistoryEvidence } from '../../components/evidence/history-evidence/history-evidence';
import { ProtectedDepositAreasFields } from './protected-deposit-areas-fields';
import { useColumns } from './protected-deposit-areas-columns';
import { useValidationSchema } from './protected-deposit-areas-schema';
import { ProtectedDepositAreasActionbar } from './protected-deposit-areas-actionbar';
import { useHistoryDescriptors } from './protected-deposit-areas-history';
import { PageKey, EvidenceAPI, Permission, Messages } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import {
  ProtectedDepositArea,
  ChluState,
  AreaType,
  ChluRuianState,
  Exception,
} from '../../models';

const convertKm2ToM2 = (item: ProtectedDepositArea) => {
  if (item.area) {
    return { ...item, area: Number((item.area * 1000000).toFixed(0)) };
  }

  return item;
};

const convertM2ToKm2 = (item: ProtectedDepositArea) => {
  if (item.area) {
    return { ...item, area: item.area / 1000000 };
  }

  return item;
};

export function ProtectedDepositAreas() {
  const columns = useColumns();
  const historyDescriptors = useHistoryDescriptors();
  const validationSchema = useValidationSchema();

  const permissions = usePermission({
    options: {
      NEW: [Permission.Chlu.CHLU_CREATE],
      EDIT: [Permission.Chlu.CHLU_UPDATE],
      REMOVE: [Permission.Chlu.CHLU_DELETE],
      CHECK: [Permission.Chlu.CHLU_CREATE, Permission.Chlu.CHLU_UPDATE],
      SAVE: [Permission.Chlu.CHLU_CREATE, Permission.Chlu.CHLU_UPDATE],
      CLOSE: [Permission.Chlu.CHLU_CREATE, Permission.Chlu.CHLU_UPDATE],
      EXPORT: [Permission.Chlu.CHLU_LIST],
    },
  });

  const props = {
    version: 2,
    identifier: PageKey.PROTECTED_DEPOSIT_AREAS,
    apiProps: {
      url: EvidenceAPI.PROTECTED_DEPOSIT_AREAS,
      createItem: createItemFactory({
        preprocessCall: async (item: ProtectedDepositArea) => {
          if (item.type === AreaType.CHUZZZK) {
            delete item.deposits;
            delete item.minerals;
            delete item.mineralGroups;
          }

          return convertKm2ToM2(item);
        },
        postprocessCall: (item: ProtectedDepositArea | Exception, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.ProtectedDepositArea.CREATE,
              (item as Exception).code
            )[0];

            throw { message };
          }

          item = item as ProtectedDepositArea;
          if (item?.area) {
            item.area = item.area / 1000000;
          }
        },
      }),
      getItem: getItemFactory({
        postProcess: (item: ProtectedDepositArea) => convertM2ToKm2(item),
      }),
      updateItem: updateItemFactory({
        preProcess: (item: ProtectedDepositArea) => convertKm2ToM2(item),
        postProcess: (item: ProtectedDepositArea) => convertM2ToKm2(item),
      }),
    },
    tableProps: {
      columns,
      tableName: 'Chráněná ložisková území',
      reportTag: ExportTags.PROTECTED_DEPOSIT_AREAS,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: ProtectedDepositAreasFields,
      validationSchema,
      reportTag: ExportTags.PROTECTED_DEPOSIT_AREAS_DETAIL,
      toolbarProps: {
        title: 'Chráněné ložiskové území',
        ActionBar: ProtectedDepositAreasActionbar,
        showBtn: permissions,
        disableBtn: function useDisable(btn: string) {
          const { source } = useContext<DetailHandle<ProtectedDepositArea>>(
            DetailContext
          );

          if (btn === 'EDIT' && source?.data?.state) {
            const isConceptOrDiscarded = [
              ChluState.CONCEPT,
              ChluState.DISCARDED,
            ].includes(source?.data?.state);

            const isAcceptedInRuian =
              source?.data?.state === ChluState.APPROVED &&
              source?.data?.inRuian &&
              source?.data?.ruianState === ChluRuianState.ACCEPTED;

            const isDeniedByRuian =
              [ChluState.APPROVED, ChluState.CANCELLED].includes(
                source?.data.state
              ) && source?.data?.ruianState === ChluRuianState.DENIED;

            return (
              !isConceptOrDiscarded && !isAcceptedInRuian && !isDeniedByRuian
            );
          }

          if (btn === 'REMOVE' && source?.data?.state) {
            return ChluState.DISCARDED !== source?.data?.state;
          }

          return false;
        },
      },
      initNewItem: () => ({
        id: uuidv4(),
        state: ChluState.CONCEPT,
      }),
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Chlu.CHLU_LIST,
    },
  };

  const evidence = useHistoryEvidence<ProtectedDepositArea>(props);

  return <Evidence2 {...evidence} />;
}
