import React from 'react';
import { DetailToolbar } from '../../components/evidence/evidence/detail-toolbar';
import { DetailToolbarProps } from '@eas/common-web';
import { RoleInstance } from '../../models';
import { useSaveDialog } from './dialog-actions/save-hook';

export function RoleInstancesToolbar(props: DetailToolbarProps<RoleInstance>) {
  const { handleSave } = useSaveDialog();

  return <DetailToolbar {...props} handleSave={handleSave} />;
}
