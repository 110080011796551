import React from 'react';
import { Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { useValidationSchema } from './dict-dirp-authors-schema';
import { DictionaryDirpAuthor } from '../../models';
import { Permission, PageKey, EvidenceAPI } from '../../enums';
import { ExportTags } from '../../enums/export-tags';

export function DictionaryDirpAuthors() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryDirpAuthor>(
    {
      identifier: PageKey.DICTIONARY_DIRP_AUTHORS,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_DIRP_AUTHORS,
      },
      tableProps: {
        tableName: 'Autoři',
        reportTag: ExportTags.DICTIONARY_DIRP_AUTHORS,
        columns: [],
        defaultSorts: [],
      },
      detailProps: {
        codePrefix: 'DIRP_AUTHOR',
        validationSchema,
        toolbarProps: {
          title: 'Autor',
        },
      },
    },
    Permission.Dirp.DIRP_INVALIDATE,
    Permission.Dirp.DIRP_REVALIDATE
  );

  return <Evidence {...evidence} />;
}
