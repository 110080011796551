import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionarySewerType } from '../../models';
import { useValidationSchema } from './dict-sewer-types-schema';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { usePermission } from '../../components/permission/perm-evidence-hook';

export function DictionarySewerTypes() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<DictionarySewerType>({
    options: {
      NEW: [Permission.MuvSewerType.MUV_SEWER_TYPE_CREATE],
      EDIT: [Permission.MuvSewerType.MUV_SEWER_TYPE_UPDATE],
      REMOVE: [Permission.MuvSewerType.MUV_SEWER_TYPE_DELETE],
      CHECK: [
        Permission.MuvSewerType.MUV_SEWER_TYPE_CREATE,
        Permission.MuvSewerType.MUV_SEWER_TYPE_UPDATE,
      ],
      SAVE: [
        Permission.MuvSewerType.MUV_SEWER_TYPE_CREATE,
        Permission.MuvSewerType.MUV_SEWER_TYPE_UPDATE,
      ],
      CLOSE: [
        Permission.MuvSewerType.MUV_SEWER_TYPE_CREATE,
        Permission.MuvSewerType.MUV_SEWER_TYPE_UPDATE,
      ],
    },
  });

  const evidence = useDictionaryEvidence<DictionarySewerType>(
    {
      identifier: PageKey.DICTIONARY_SEWER_TYPES,
      apiProps: {
        url: EvidenceAPI.DICTIONARY_SEWER_TYPES,
      },
      tableProps: {
        tableName: 'Typy kanalizace',
        reportTag: ExportTags.DICTIONARY_SEWER_TYPES,
        defaultSorts: [],
      },
      detailProps: {
        codePrefix: 'SEWER_TYPE',
        validationSchema,
        toolbarProps: {
          title: 'Typ kanalizace',
          showBtn: permissions,
        },
      },
    },
    Permission.MuvSewerType.MUV_SEWER_TYPE_INVALIDATE,
    Permission.MuvSewerType.MUV_SEWER_TYPE_REVALIDATE
  );

  return <Evidence2 {...evidence} />;
}
