import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  exportTemplatesFactory,
  Evidence2,
  ExportContext,
  ExportTemplate,
  TemplateContext,
} from '@eas/common-web';
import { getItem, createItem, updateItem } from './export-api';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { useColumns } from './export-templates-columns';

export function DictionaryExportTemplates() {
  const { url } = useContext(ExportContext);

  const validationSchema = exportTemplatesFactory.useValidationSchema();

  const evidence = useDictionaryEvidence<ExportTemplate>({
    identifier: 'EXPORT_TEMPLATES',
    apiProps: {
      url: `${url}/templates`,
      getItem,
      updateItem,
      createItem,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled šablon exportu',
      reportTag: ExportTags.EXPORT_TEMPLATE,
    },
    detailProps: {
      codePrefix: 'EXPORT_TEMPLATES',
      validationSchema,
      initNewItem: () => ({
        id: uuidv4(),
        name: '',
        params: '{}',
      }),
      toolbarProps: {
        title: 'Šablona',
      },
      FieldsComponent: exportTemplatesFactory.Fields,
    },
  });

  return (
    <TemplateContext.Provider value={{ enableDocx4j: true }}>
      <Evidence2 {...evidence} />
    </TemplateContext.Provider>
  );
}
