import React, { useContext } from 'react';
import { noop } from 'lodash';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import GetAppIcon from '@material-ui/icons/GetApp';
import MuiButton from '@material-ui/core/Button';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LinkIcon from '@material-ui/icons/Link';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailContext,
  DetailHandle,
  DetailToolbarButtonType,
  DetailMode,
  UserContext,
  DetailActionbarButton,
} from '@eas/common-web';
import { SubjectRegistration, SubjectRegistrationState } from '../../models';
import { EvidenceAPI, Permission } from '../../enums';
import { useAuthorizeDialog } from './dialog-actions/authorize-hook';
import { useApproveDialog } from './dialog-actions/approve-hook';
import { useConfirmDialog } from './dialog-actions/confirm-hook';
import { useCancelDialog } from './dialog-actions/cancel-hook';
import { useRejectDialog } from './dialog-actions/reject-hook';
import { useSync } from './actions/sync-hook';
import { EntityType } from '../../enums/entity-type';
import { useCreateNoteDialog } from '../../components/evidence/noted-evidence/dialog-actions/create-note-hook';
import { useLinkToDialog } from './dialog-actions/link-to-hook';
import { useEditCeniaDetailsDialog } from './dialog-actions/edit-cenia-details-hook';

const useStyles = makeStyles({
  actionbarButton: {
    minWidth: 40,
    width: 'max-content',
    cursor: 'pointer',
    marginTop: 1,
    marginLeft: 6,
    backgroundColor: '#e0e0e0',
    border: 0,
    '&:hover': {
      backgroundColor: '#d0d0d0',
    },
  },
});

export function SubjectRequestsActionbar() {
  const classes = useStyles();

  const { mode, source, startEditing } = useContext<
    DetailHandle<SubjectRegistration>
  >(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const { handleAuthorize, showAuthorizeButton } = useAuthorizeDialog();
  const { handleConfirm, showConfirmButton } = useConfirmDialog();
  const { handleCancel, showCancelButton } = useCancelDialog();
  const { handleApprove, showApproveButton } = useApproveDialog();
  const { handleLinkTo, showLinkToButton } = useLinkToDialog();
  const { handleReject } = useRejectDialog();
  const { handleSynchronize } = useSync();
  const {
    handleEdit,
    showEditCeniaDetailsButton,
  } = useEditCeniaDetailsDialog();

  const isAdmin = hasPermission(Permission.Subject.SUBJECT_REQUEST_LIST_ALL);

  const showEditAndApproveButton =
    (source.data?.state === SubjectRegistrationState.MANUAL_FINISH_REQUIRED ||
      source.data?.state === SubjectRegistrationState.WAITING_FOR_ZR_SYNC) &&
    hasPermission(Permission.Subject.SUBJECT_REQUEST_FINISH);

  const {
    handleCreateNote,
    showCreateButton: showCreateNote,
  } = useCreateNoteDialog({
    entityType: EntityType.SUBJECT_REGISTRATION,
  });

  return (
    <div style={{ flexWrap: 'wrap' }}>
      {showApproveButton && (
        <DetailActionbarButton
          startIcon={<DoneIcon />}
          label="Schválit"
          // tooltip="Manuální schválení žádosti o registraci subjektu"
          type={DetailToolbarButtonType.PRIMARY}
          onClick={handleApprove}
        />
      )}
      {mode === DetailMode.VIEW && (
        <>
          {(showAuthorizeButton || showConfirmButton || showCancelButton) && (
            <>
              {showAuthorizeButton && (
                <DetailActionbarButton
                  startIcon={<DoneIcon />}
                  label="Dokončit registraci"
                  // tooltip="Autorizovat žádost o registraci subjektu podepsaným dokumentem"
                  type={DetailToolbarButtonType.PRIMARY}
                  onClick={handleAuthorize}
                />
              )}
              {showConfirmButton && (
                <DetailActionbarButton
                  startIcon={<DoneIcon />}
                  label="Autorizovat"
                  // tooltip="Autorizovat žádost o registraci subjektu skrze doručený dokument"
                  type={DetailToolbarButtonType.PRIMARY}
                  onClick={handleConfirm}
                />
              )}
              {showCancelButton && (
                <DetailActionbarButton
                  startIcon={<CloseIcon />}
                  label="Zamítnout"
                  // tooltip="Zamítnutí žádosti o registraci subjektu"
                  onClick={handleCancel}
                  type={DetailToolbarButtonType.SECONDARY}
                />
              )}
            </>
          )}

          {showLinkToButton && (
            <DetailActionbarButton
              startIcon={<LinkIcon fontSize="small" />}
              label="Propojit"
              onClick={handleLinkTo}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}

          {showEditCeniaDetailsButton && (
            <DetailActionbarButton
              startIcon={<EditIcon />}
              label="Upravit Ev.č./Č.j."
              type={DetailToolbarButtonType.PRIMARY}
              onClick={handleEdit}
            />
          )}

          {showEditAndApproveButton && (
            <>
              <DetailActionbarButton
                startIcon={<DoneIcon />}
                label="Upravit a schválit"
                // tooltip="Manuální schválení žádosti o registraci subjektu"
                type={DetailToolbarButtonType.PRIMARY}
                onClick={startEditing}
              />
              <DetailActionbarButton
                startIcon={<CloseIcon />}
                label="Zamítnout"
                // tooltip="Zamítnutí žádosti o registraci subjektu"
                type={DetailToolbarButtonType.SECONDARY}
                onClick={handleReject}
              />
              <DetailActionbarButton
                startIcon={<SyncAltIcon />}
                label="Synchronizovat"
                // tooltip="Pokusí se synchronizovat se ZR, pokud se synchronizace podaří, žádost o registraci bude automaticky schválena"
                onClick={handleSynchronize}
              />
            </>
          )}

          {showCreateNote && (
            <DetailActionbarButton
              startIcon={<NoteAddIcon fontSize="small" />}
              label="Přidat poznámku"
              onClick={handleCreateNote}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}

          {(showAuthorizeButton || isAdmin) &&
            source.data?.documentSignature?.signedFile?.id && (
              <MuiButton
                startIcon={<GetAppIcon />}
                classes={{ root: classes.actionbarButton }}
                // tooltip="Stáhne registrační dokument"
                onClick={noop}
                href={`${EvidenceAPI.ARCHIVED_FILES}/${source.data.documentSignature.signedFile.id}`}
                download
                variant="outlined"
                size="small"
              >
                <Typography variant="body2">Autorizační potvrzení</Typography>
              </MuiButton>
            )}

          {(showAuthorizeButton || isAdmin) &&
            source.data?.documentSignature?.userUploadedFile?.id && (
              <DetailActionbarButton
                startIcon={<GetAppIcon />}
                label="Stáhnout PDF"
                tooltip="Pro migrované data - registrační PDF, pro nové subjekty - autorizační potvrzení"
                onClick={noop}
                href={`${EvidenceAPI.ARCHIVED_FILES}/${source.data.documentSignature.userUploadedFile.id}`}
              />
            )}

          {(showAuthorizeButton || isAdmin) &&
            source.data?.ispopDocument?.file?.id && (
              <DetailActionbarButton
                startIcon={<GetAppIcon />}
                label="Stáhnout dokument přijat z ISDS"
                onClick={noop}
                href={`${EvidenceAPI.ARCHIVED_FILES}/${source.data.ispopDocument.file.id}`}
              />
            )}
        </>
      )}
    </div>
  );
}
