import React from 'react';
import {
  Evidence2,
  getItemFactory,
  updateItemFactory,
  createItemFactory,
} from '@eas/common-web';
import { PublicListsFields } from './public-lists-fields';
import { useColumns } from './public-lists-columns';
import { useValidationSchema } from './public-lists-schema';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { PublicListsActionbar } from './public-lists-actionbar';
import { PublicList } from '../../models/public-list';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { useHistoryDescriptors } from './public-lists-history';

export function PublicLists() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<PublicList>({
    options: {
      NEW: [],
      EDIT: [Permission.PublicList.PUBLIC_LIST_UPDATE],
      REMOVE: [Permission.PublicList.PUBLIC_LIST_DELETE],
      CHECK: [Permission.PublicList.PUBLIC_LIST_UPDATE],
      SAVE: [Permission.PublicList.PUBLIC_LIST_UPDATE],
      CLOSE: [Permission.PublicList.PUBLIC_LIST_UPDATE],
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useDictionaryEvidence<PublicList>({
    identifier: PageKey.PUBLIC_LISTS,
    apiProps: {
      url: EvidenceAPI.PUBLIC_LISTS,
      getItem: getItemFactory<PublicList>({
        postProcess: (item) => ({
          ...item,
          params: JSON.stringify(item.params),
        }),
      }),
      updateItem: updateItemFactory<PublicList>({
        preProcess: (item) => ({
          ...item,
          params: JSON.parse(item.params),
        }),
        postProcess: (item) => ({
          ...item,
          params: JSON.stringify(item.params),
        }),
      }),
      createItem: createItemFactory<PublicList>({
        preProcess: (item) => ({
          ...item,
          params: JSON.parse(item.params),
        }),
        postProcess: (item) => ({
          ...item,
          params: JSON.stringify(item.params),
        }),
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled veřejných seznamů',
      reportTag: ExportTags.PUBLIC_LISTS,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'PUBLIC_LIST',
      FieldsComponent: PublicListsFields,
      toolbarProps: {
        title: 'Veřejný seznam',
        showBtn: permissions,
        ActionBar: PublicListsActionbar,
      },
      validationSchema,
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.PublicList.PUBLIC_LIST_LIST,
    },
  });

  return <Evidence2 {...evidence} />;
}
