import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { DictionaryRiverBasinCzDistrict } from '../../models';
import { useValidationSchema } from './dict-river-basin-cz-districts-schema';
import { EvidenceAPI, PageKey } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { useDictionaryEvidence } from '../../components/evidence/dictionary-evidence/dictionary-evidence';
import { DictRiverBasinCzDistrictsFields } from './dict-river-basin-cz-districts-fields';

export function DictionaryRiverBasinCzDistricts() {
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<DictionaryRiverBasinCzDistrict>({
    identifier: PageKey.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS,
    apiProps: {
      url: EvidenceAPI.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS,
    },
    tableProps: {
      tableName: 'Oblasti povodí ČR',
      reportTag: ExportTags.DICTIONARY_RIVER_BASIN_CZ_DISTRICTS,
      defaultSorts: [],
    },
    detailProps: {
      codePrefix: 'CZ_RIVER_BASIN_DISTRICT',
      GeneralFieldsComponent: DictRiverBasinCzDistrictsFields,
      validationSchema,
      toolbarProps: {
        title: 'Oblast povodí ČR',
      },
    },
  });

  return <Evidence2 {...evidence} />;
}
