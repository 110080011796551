import React from 'react';
import { CityDistrict } from '../../models';
import { PageKey, EvidenceAPI } from '../../enums';

import { ExportTags } from '../../enums/export-tags';
import { Evidence2 } from '@eas/common-web';
import { useRuianEvidence } from '../../components/evidence/ruian-evidence/ruian-evidence';
import DictionaryRuianCityDistrictFields from './dict-ruian-city-district-fields';

export function DictionaryRuianCityDistrict() {
  const evidence = useRuianEvidence<CityDistrict>(
    {
      identifier: PageKey.DICTIONARY_RUIAN_CITY_DISTRICT,
      apiProps: {
        url: EvidenceAPI.ADDRESS_DISCTRICT_CITY,
      },
      tableProps: {
        tableName:
          'RUIAN - Městské obvody/městské části územně členěného statutárního města (MOMC)',
        reportTag: ExportTags.DICTIONARY_DISTRICT_CITY,
      },
      detailProps: {
        FieldsComponent: DictionaryRuianCityDistrictFields,
        toolbarProps: {
          title:
            'RUIAN - Městský obvod/městská část územně členěného statutárního města (MOMC)',
        },
      },
    },
    {
      hasMunicipality: true,
    }
  );

  return <Evidence2 {...evidence} />;
}
