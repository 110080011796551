import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { v4 as uuidv4 } from 'uuid';
import { MailCampaignsFields } from './mail-campaigns-fields';
import { useColumns } from './mail-campaigns-columns';
import { useValidationSchema } from './mail-campaigns-schema';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { ExportTags } from '../../enums/export-tags';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { MailCampaign, MailCampaignState } from '../../models/mail-campaign';
import { MailCampaignActionbar } from './mail-campaigns-actionbar';
import { MailCampaignsTableToolbar } from './mail-campaings-table-toolbar';
import { useHistoryDescriptors } from './mail-campaigns-history';

export function MailCampaigns() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission<MailCampaign>({
    options: {
      NEW: [Permission.Mailing.MAILING_CREATE],
      EDIT: [Permission.Mailing.MAILING_UPDATE],
      REMOVE: [Permission.Mailing.MAILING_DELETE],
      CHECK: [
        Permission.Mailing.MAILING_CREATE,
        Permission.Mailing.MAILING_UPDATE,
      ],
      SAVE: [
        Permission.Mailing.MAILING_CREATE,
        Permission.Mailing.MAILING_UPDATE,
      ],
      CLOSE: [
        Permission.Mailing.MAILING_CREATE,
        Permission.Mailing.MAILING_UPDATE,
      ],
    },
    dataCheck: {
      EDIT: (campaign) => campaign?.state === MailCampaignState.CONCEPT,
      REMOVE: (campaign) => campaign?.state === MailCampaignState.CONCEPT,
    },
  });

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<MailCampaign>({
    identifier: PageKey.MAILING,
    apiProps: {
      url: EvidenceAPI.MAILING,
    },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled mailových kampaní',
      reportTag: ExportTags.MAILING,
      defaultSorts: [],
      toolbarProps: {
        after: <MailCampaignsTableToolbar />,
      },
    },
    detailProps: {
      FieldsComponent: MailCampaignsFields,
      toolbarProps: {
        title: 'Mailová kampaň',
        showBtn: permissions,
        ActionBar: MailCampaignActionbar,
      },
      initNewItem: () => ({
        id: uuidv4(),
        state: MailCampaignState.CONCEPT,
        name: '',
        emailSubject: '',
        emailContent: '',
        recipients: [],
      }),
      validationSchema,
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Mailing.MAILING_LIST,
    },
  });

  return <Evidence2 {...evidence} />;
}
