import React, { useMemo, useContext } from 'react';
import { Evidence2, UserContext } from '@eas/common-web';
import { v4 as uuidv4 } from 'uuid';
import { Role, Me } from '../../models';
import { RolesFields } from './roles-fields';
import { useColumns } from './roles-columns';
import { useValidationSchema } from './roles-schema';
import {
  EvidenceAPI,
  Permission,
  IncludeOptions,
  Messages,
  PageKey,
} from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { ExportTags } from '../../enums/export-tags';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { DictionaryActionbar } from '../../components/evidence/dictionary-evidence/dictionary-actionbar';
import { useHistoryDescriptors } from './roles-history';
import { createItemFactory, updateItemFactory } from '../../utils/custom-crud';
import { getErrorMessage } from '../../utils/get-message';

export function Roles() {
  const validationSchema = useValidationSchema();

  const permissions = usePermission({
    options: {
      NEW: [Permission.Role.ROLE_CREATE],
      EDIT: [Permission.Role.ROLE_UPDATE],
      REMOVE: [],
      CHECK: [Permission.Role.ROLE_CREATE, Permission.Role.ROLE_UPDATE],
      SAVE: [Permission.Role.ROLE_CREATE, Permission.Role.ROLE_UPDATE],
      CLOSE: [Permission.Role.ROLE_CREATE, Permission.Role.ROLE_UPDATE],
    },
  });

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const canSeeDeactivated = useMemo(
    () => hasPermission(Permission.Role.ROLE_INVALIDATE),
    [hasPermission]
  );

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<Role>({
    identifier: PageKey.ROLES,
    apiProps: {
      url: EvidenceAPI.ROLES,
      createItem: createItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(Messages.Role.CREATE, data.code)[0];

            throw { message };
          }
        },
      }),
      updateItem: updateItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(Messages.Role.UPDATE, data.code)[0];

            throw { message };
          }
        },
      }),
    },
    tableProps: {
      columns: useColumns(canSeeDeactivated),
      tableName: 'Přehled rolí',
      reportTag: ExportTags.ROLES,
      include,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: RolesFields,
      validationSchema,
      toolbarProps: {
        title: 'Role',
        ActionBar: function ActionBar() {
          return (
            <DictionaryActionbar
              url={EvidenceAPI.ROLES}
              invalidatePerm={Permission.Role.ROLE_INVALIDATE}
              showBtn={(button) => {
                if (button === 'REVALIDATE') {
                  return false;
                }
                return true;
              }}
            />
          );
        },
        showBtn: permissions,
      },
      initNewItem: () => ({
        id: uuidv4(),
        autoAssignToSubjectAdmin: true,
      }),
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Role.ROLE_LIST,
    },
  });

  return <Evidence2 {...evidence} />;
}
