import {
  DictionaryAutocomplete,
  useListSource,
  UserContext,
  useAutocompleteSource,
  useStaticListSource,
} from '@eas/common-web';
import { CombineCallsType } from '../../utils/custom-crud/custom-crud-types';
import { EvidenceAPI, Permission } from '../../enums';
import { useContext } from 'react';
import {
  Me,
  RoleInstanceType,
  RoleAutocomplete,
  RoleInstance,
} from '../../models';

export function useLocalRoleInstanceTypes() {
  return useStaticListSource([
    { id: 'RELATIONSHIP_ROLE_INSTANCE', name: 'Vztahová role' },
    { id: 'SECURITY_ROLE_INSTANCE', name: 'Bezpečnostní role' },
    { id: 'SYSTEM_ROLE_INSTANCE', name: 'Systémová role' },
    { id: 'USER_ROLE_INSTANCE', name: 'Uživatelská role' },
  ]);
}

export function useRoleInstanceTypes() {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const source = useListSource<DictionaryAutocomplete>({
    url: EvidenceAPI.ROLE_INSTANCES + '/type/list',
    method: 'GET',
  });

  if (hasPermission(Permission.Role.ROLE_ASSIGN_SELECTED)) {
    return source;
  }

  if (!hasPermission(Permission.Role.ROLE_ASSIGN_RELATIONSHIP_ROLE)) {
    source.items = source.items.filter(
      (item) => item.id !== RoleInstanceType.RELATIONSHIP_ROLE_INSTANCE
    );
  }

  if (!hasPermission(Permission.Role.ROLE_ASSIGN_SECURITY_ROLE)) {
    source.items = source.items.filter(
      (item) => item.id !== RoleInstanceType.SECURITY_ROLE_INSTANCE
    );
  }

  if (!hasPermission(Permission.Role.ROLE_ASSIGN_SYSTEM_ROLE)) {
    source.items = source.items.filter(
      (item) => item.id !== RoleInstanceType.SYSTEM_ROLE_INSTANCE
    );
  }

  if (!hasPermission(Permission.Role.ROLE_ASSIGN_USER_ROLE)) {
    source.items = source.items.filter(
      (item) => item.id !== RoleInstanceType.USER_ROLE_INSTANCE
    );
  }

  return source;
}

export function useRoles() {
  const source = useAutocompleteSource<RoleAutocomplete>({
    url: EvidenceAPI.ROLES + '/autocomplete',
  });

  return source;
}

export async function combineGetCalls(data: CombineCallsType) {
  const json = {
    ...data.mainItem,
  };

  const text = JSON.stringify(json);

  if (data?.mainItem?.sepnoDetails !== undefined) {
    json['sepnoDetails'] = {
      icps: data.mainItem.sepnoDetails.icps.map((icps: string) => ({
        name: icps,
      })),
      iczs: data.mainItem.sepnoDetails.iczs.map((iczs: string) => ({
        name: iczs,
      })),
    };
  }

  return { data: json, text };
}

export async function preprocessCall(data: RoleInstance) {
  if (data.sepnoDetails !== undefined) {
    return {
      ...data,
      sepnoDetails: {
        icps: (data.sepnoDetails.icps ?? []).map(
          (icps) => (icps as { name: string }).name
        ),
        iczs: (data.sepnoDetails.iczs ?? []).map(
          (iczs) => (iczs as { name: string }).name
        ),
      },
    };
  }

  return data;
}
