import React, { useContext, useMemo } from 'react';
import { Evidence2, UserContext } from '@eas/common-web';
import { useValidationSchema } from './notification-templates-schema';
import { NotificationTemplatesFields } from './notification-templates-fields';
import { useColumns } from './notification-templates-columns';
import { ExportTags } from '../../enums/export-tags';
import {
  NotificationTemplate,
  Exception,
  Me,
  PermissionsState,
} from '../../models';
import {
  EvidenceAPI,
  PageKey,
  Permission,
  Messages,
  IncludeOptions,
} from '../../enums';
import { NotificationTemplateContext } from './notification-templates-context';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { NotificationTemplatesActionbar } from './notification-templates-actionbar';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { useHistoryDescriptors } from './notification-templates-history';
import { createItemFactory, updateItemFactory } from '../../utils/custom-crud';
import { DictionaryActionbar } from '../../components/evidence/dictionary-evidence/dictionary-actionbar';
import { getErrorMessage } from '../../utils/get-message';

export function NotificationTemplates() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const permissions = usePermission<NotificationTemplate>({
    options: {
      NEW: [Permission.Notification.NOTIFICATION_TEMPLATE_CREATE],
      EDIT: [Permission.Notification.NOTIFICATION_TEMPLATE_UPDATE],
      REMOVE: [Permission.Notification.NOTIFICATION_TEMPLATE_DELETE],
      CHECK: [
        Permission.Notification.NOTIFICATION_TEMPLATE_CREATE,
        Permission.Notification.NOTIFICATION_TEMPLATE_UPDATE,
      ],
      SAVE: [
        Permission.Notification.NOTIFICATION_TEMPLATE_CREATE,
        Permission.Notification.NOTIFICATION_TEMPLATE_UPDATE,
      ],
      CLOSE: [
        Permission.Notification.NOTIFICATION_TEMPLATE_CREATE,
        Permission.Notification.NOTIFICATION_TEMPLATE_UPDATE,
      ],
    },
  });

  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const historyDescriptors = useHistoryDescriptors();

  const canSeeDeactivated = useMemo(
    () =>
      hasPermission(Permission.Notification.NOTIFICATION_TEMPLATE_INVALIDATE),
    [hasPermission]
  );

  const include = useMemo(
    () => (canSeeDeactivated ? [IncludeOptions.DEACTIVATED] : []),
    [canSeeDeactivated]
  );

  const evidence = useAuthoredEvidence<NotificationTemplate>({
    identifier: PageKey.NOTIFICATION_TEMPLATES,
    apiProps: {
      url: EvidenceAPI.NOTIFICATION_TEMPLATES,
      createItem: createItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.Notification.CREATE,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
      updateItem: updateItemFactory({
        postprocessCall: (data, response) => {
          if (!response?.ok) {
            const message = getErrorMessage(
              Messages.Notification.EDIT,
              (data as Exception).code
            )[0];

            throw { message };
          }
        },
      }),
    },
    tableProps: {
      columns,
      tableName: 'Přehled šablon notifikací',
      reportTag: ExportTags.NOTIFICATION_TEMPLATES,
      defaultSorts: [],
      include,
    },
    detailProps: {
      FieldsComponent: NotificationTemplatesFields,
      validationSchema,
      toolbarProps: {
        title: 'Šablona notifikace',
        ActionBar: function ActionBar() {
          return (
            <DictionaryActionbar
              url={EvidenceAPI.NOTIFICATION_TEMPLATES}
              CustomActions={NotificationTemplatesActionbar}
              invalidatePerm={
                Permission.Notification.NOTIFICATION_TEMPLATE_INVALIDATE
              }
              revalidatePerm={
                Permission.Notification.NOTIFICATION_TEMPLATE_REVALIDATE
              }
            />
          );
        },
        showBtn: permissions,
      },
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Notification.NOTIFICATION_TEMPLATE_LIST,
    },
  });

  return (
    <NotificationTemplateContext.Provider value={{}}>
      <Evidence2 {...evidence} />
    </NotificationTemplateContext.Provider>
  );
}
