import React from 'react';
import { Evidence2 } from '@eas/common-web';
import { SubjectRegistration, SubjectRegistrationState } from '../../models';
import { SubjectRequestsFields } from './subject-requests-fields';
import {
  useColumns,
  subjectRegistrationColumnFactory,
} from './subject-requests-columns';
import { EvidenceAPI, PageKey, Permission } from '../../enums';
import { usePermission } from '../../components/permission/perm-evidence-hook';
import { SubjectRequestToolbar } from './subject-requests-toolbar';
import { ExportTags } from '../../enums/export-tags';
import { useAuthoredEvidence } from '../../components/evidence/authored-evidence/authored-evidence';
import { EntityType } from '../../enums/entity-type';
import { SubjectRequestsActionbar } from './subject-requests-actionbar';
import { useSubTitle } from '../../components/evidence/evidence/sub-title-hook';
import { useSpecialFilters } from './subject-requests-special-filters';
import { useValidationSchema } from './subject-requests-schema';
import { SubjectRequestsTableToolbar } from './subject-requests-table-toolbar';
import { useHistoryDescriptors } from './subject-requests-history';

function EmptyWrapper({ children }: { children?: React.ReactNode }) {
  return <>{children}</>;
}

export function SubjectRequests() {
  const permissions = usePermission<SubjectRegistration>({
    options: {
      NEW: [],
      EDIT: [],
      REMOVE: [],
      CHECK: [Permission.Subject.SUBJECT_REQUEST_LIST_ALL],
      SAVE: [],
      CLOSE: [Permission.Subject.SUBJECT_REQUEST_FINISH],
    },
    dataCheck: {
      CHECK: (data) =>
        data?.state === SubjectRegistrationState.MANUAL_FINISH_REQUIRED ||
        data?.state === SubjectRegistrationState.WAITING_FOR_ZR_SYNC,
      CLOSE: (data) =>
        data?.state === SubjectRegistrationState.MANUAL_FINISH_REQUIRED ||
        data?.state === SubjectRegistrationState.WAITING_FOR_ZR_SYNC,
    },
  });

  const validationSchema = useValidationSchema();

  const historyDescriptors = useHistoryDescriptors();

  const evidence = useAuthoredEvidence<SubjectRegistration>({
    noteEntityType: EntityType.SUBJECT_REGISTRATION,
    identifier: PageKey.SUBJECT_REQUESTS,
    apiProps: { url: EvidenceAPI.SUBJECT_REQUESTS },
    tableProps: {
      columns: useColumns(),
      tableName: 'Přehled žádostí o registraci subjektu',
      reportTag: ExportTags.SUBJECT_REQUESTS,
      defaultSorts: [],
      specialFilters: useSpecialFilters(),
      specialFiltersPerm: Permission.Subject.SUBJECT_REQUEST_LIST_ALL,
      columnWrapper: subjectRegistrationColumnFactory,
      toolbarProps: {
        after: <SubjectRequestsTableToolbar />,
      },
    },
    detailProps: {
      FieldsComponent: SubjectRequestsFields,
      ContainerComponent: EmptyWrapper,
      hideGeneralFields: true,
      toolbarProps: {
        title: 'Registrace subjektu',
        subtitle: useSubTitle<SubjectRegistration>((data) =>
          data?.evidenceNumber ? `EVČ ${data?.evidenceNumber?.toString()}` : ''
        ),
        After: SubjectRequestToolbar,
        ActionBar: SubjectRequestsActionbar,
        showBtn: permissions,
      },
      validationSchema,
    },
    historyProps: {
      descriptors: historyDescriptors,
      permission: Permission.Subject.SUBJECT_REQUEST_LIST_ALL,
    },
  });

  return <Evidence2 {...evidence} />;
}
